import {mapState} from './store/index.js'

export default {
	render(h) {
		return (
			<div class="c-table-head-wrapper" style={`left: ${this.left}; width: ${this.compWidth}`}>
				<div style={`min-width: ${this.columnsWidth.all}px; padding-right: ${this.scrollYSize}px`}>
					<table>
						<colgroup>
							{
								this.columns.map((col, i) => <col width={col.width} />)
							}
						</colgroup>
						<thead>
							{
								this.header.map((row, i) => {
									return [
										<tr>
											{
												row.map((col, j) => {
													return [
														h('th', {
															class: `align-${col.align}`,
															attrs: {
																rowspan: col.rowspan,
																colspan: col.colspan
															}
														}, col.renderHead())
													]
												})
											}
										</tr>
									]
								})
							}
						</thead>
					</table>
				</div>
			</div>
		)
	},
	
	props: {
		store: Object,
		
		fixed: String,
        
        left: String
	},
	
	data() {
		return {
			row: {
				a: 2
			}
		}
	},
	
	computed: {
		...mapState(['header', 'columns', 'columnsWidth', 'scrollYSize']),
	
		compWidth() {
			return this.fixed ? `${this.columnsWidth.all}px` : '100%';
		}
	}
};