<template>
    <button class="c-button" :class="[{'border': hollow, 'z-dis': disabled}, `s-${color}`]"
		:style="{
			'min-width': compWidth,
			'height': compHeight,
			'border-color': background,
			'border-radius': compRadius,
			'font-size': compFontSize,
			'color': fontColor,
			'background': background
		}"
		:disabled="!!disabled" @click="$event => $emit('click', $event)"
	>
		<div class="flex-center">
			<i v-if="iconClass" class="tf" :class="[iconClass]"></i>
			<span><slot></slot></span>
		</div>
	</button>
</template>

<script>
	import {mapState} from 'vuex'
	
	export default {
		name: 'cButton',
		
		props: {
			power: String,
			
			width: [Number, String],  // 自定义宽度
			
			height: [Number, String],  // 自定义高度
			
			color: {  // 按钮颜色（default: 系统辅色；）
				type: String,
				default: 'default'
			},
			
			hollow: Boolean,  // 是否为空心按钮
			
			radius: String,  //  圆角大小
			
			size: {  // 按钮尺寸（s：小号；m：中号；l：大号）
				type: String,
				default: 's'
			},
			
			fontSize: String,  // 字体大小
			
			fontColor: String,  // 字体颜色
			
			background: String,  //  按钮颜色
			
			icon: String,  // 按钮图标（add：添加）
			
			disabled: Boolean  // 禁用
		},
		
		computed: {
			...mapState(['mobile']),
			
			compWidth() {
				if (this.width) {
					return this.width + 'px';
				}
				
				return {
					s: '4em',
					m: '140px'
				}[this.size];
			},
			
			compHeight() {
				if (this.height) {
					return this.height + 'px';
				}
				
				return {
					s: this.mobile ? '.68rem' : '32px',
					m: '36px'
				}[this.size];
			},
			
			compRadius() {
				if (this.radius) {
					return this.radius + 'px';
				}
				
				return {
					s: '0',
					m: '0'
				}[this.size];
			},
			
			compFontSize() {
				if (this.fontSize) {
					return this.fontSize + 'px';
				}
				
				return {
					s: this.mobile ? '.28rem' : '14px',
					m: '16px'
				}[this.size];
			},
			
			iconClass() {
				return {
					'add': 'tf-plus',
					'upload': 'tf-upload'
				}[this.icon];
			}
		}
	};
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/mobile'
	
	.c-button {
		box-sizing: border-box;
		position: relative;
		border: 1px solid #1492ff;
		padding: 0 10px;
		white-space: nowrap;
		color: #fff;
		background: #1492ff;
		
		mobile({
			border-radius: .04rem,
			padding: 0 .4rem
		})
		
		&:hover {
			normal({
				border-color: #117CD9,
				background: #117CD9
			})
		}
		
		&.border,
		&.border:hover {
			color: #000;
			background: #fff !important;
		}
		
		&:not(.border),
		&:not(.border):hover {
			color: #fff !important;
		}
		
		&.z-dis {
			border: none !important;
			color: #999 !important;
			background: #eee !important;
			cursor: not-allowed !important;
		}
		
		.tf {
			margin-right: 5px;
			font-size: 15px;
		}
		
		span {
			display: inline-block;
			vertical-align: 1px;
		}
	}
	
	colorList = ({
		name: 'gray',
		normal: #ddd,
		hover: #000,
		font: #444
	} {
		name: 'yellow',
		normal: #F1960D,
		hover: #F3A838
	} {
		name: 'green',
		normal: #00B578,
		hover: #00CB9D
	} {
		name: 'red',
		normal: #E63633,
		hover: #EA5957
	});
	
	.c-button
		for item in colorList
			&.s-{item.name}
				color: item.normal;
				color: item.font;
				border-color: item.normal;
				background: item.normal;
				
			[data-mobile = false] &.s-{item.name}:hover
				color: item.hover;
				border-color: item.hover;
				background: item.hover;
</style>
