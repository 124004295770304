import Vue from "vue";

let app = new Vue();

const regexp = {
	phone: /^T?1[0-9]{10}$/,  // 手机号
	tel: /^(0[0-9]{2,3}\-?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/,  // 固定电话
	email: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,  // 邮箱
	realname: /[\u4E00-\u9FA5]{2,5}(?:·[\u4E00-\u9FA5]{2,5})*/,  // 真实姓名
	idcard: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,  // 身份证号
	username: /^([a-zA-Z0-9\u4e00-\u9fa5_-]){4,20}$/,  // 用户名，4-20位，包含汉字、字母、数字以及-、_
	password: /^[\@A-Za-z0-9\!\#\$\%\^\&\*\.\~]{6,20}$/  // 密码，6-20位，包含字母、数字以及!、@、#、$、%、^、&、*、.、~
};

var checkRule = function(data, value, rule) {
	if (['regexp', 'range'].indexOf(rule.type) > -1 && (value === '' || value === undefined)) {
		return true;
	}

	if (rule.skip && rule.skip(data)) {
		return true;
	}

	switch(rule.type) {
	case 'empty':  // 空值校验
		if (value === '' || value === undefined || value === null) return false;
		break;

	case 'regexp':  // 正则校验
		var state = true;
		for (var i in rule.regexp) {
			var re = rule.regexp[i];
			if (!regexp[re]) continue;
			if (regexp[re].test(value)) {
				state = true;
				if (rule.callback && rule.callback[re]) {
					rule.callback[re](value);
				}
				break;
			} else {
				state = false
			}
		}
		return state;

	case 'length':  // 字符串长度校验
		var str = value + '';
		if (str.length < rule.range[0] || str.length > rule.range[1]) return false;
		break;

	case 'range':  // 数字范围校验
		var num = parseFloat(value);
		if (num != num) return false;
		if (num < rule.range[0] || num > rule.range[1]) return false;
		break;

	case 'check':  // 复选数量校验
		var len = value ? value.length : 0;
		if (len < rule.total[0] || len > rule.total[1]) return false;
		break;

	case 'function':  // 自定义方法判断
		return rule.fun(value, data) || false;
	}

	return true;
}

var checkItem = function(opt, data, name, next) {
	var value = data[name], rule = opt.rule[name];
	
	if (typeof rule == 'string') {
		rule = [{
			type: 'empty',
			word: rule
		}];
	}
	
	if (!rule || !rule.length) {
		next && next(true);
		return;
	}

	function loop(index) {
		var r = rule[index];
		if (r.type == 'async') {
			opt.SYS_AJAX({
				url: r.url,
				data: (function() {
					var data = r.data || {};
					data[r.name] = value;
					return data;
				})(),
				success: function() {
					callback(index, true, r);
				}
			});
		} else {
			callback(index, checkRule(data, value, r), r);
		}
	}

	function callback(index, state, r) {
		if (state)  {
			if (!r.pass || r.pass(name) !== false) {
				opt.pass && opt.pass(name, r.type);
			}
		} else {
			if (!r.fail || r.fail(name, r.word || '提交数据有误，请检查后重试') !== false) {
				r.word !== false && opt.fail && opt.fail(name, r.type, r.word || '提交数据有误，请检查后重试');
			}
		}

		if (index == rule.length-1 || !state) {
			next && next(state);
		} else {
			loop(++index);
		}
	}

	loop(0);
};

var vaildate = function(data, opt, callback) {
	var arr = [];
	var state = true;
	for (var name in opt.rule) {
		arr.push(name);
	}
	
	if (!arr.length) {
		callback(true);
		return;
	}

	!function loop(index) {
		var name = arr[index];
		if (opt.skip && opt.skip[name] && opt.skip[name](data)) {
			itemCallback(true);
		} else {
			checkItem(opt, data, name, itemCallback);
		}

		function itemCallback(s) {
			if (!s) {
				state = false;
				if (!opt.vaildAll) {
					callback && callback(false);
					return;
				}
			}

			if (index == arr.length-1) {
				callback && callback(state);
			} else {
				loop(++index);
			}
		}
	}(0);
};

var main = function(data, opt) {
	opt = Object.assign({
		rule: {},
		fail: (n, t, m) => {
			app.$message({
				text: m
			});
		},
		success: function() {}
	}, opt);

	vaildate(data, opt, function(state) {
		if (!state) return false;
			
		opt.success(data);
	});
};

export default main;