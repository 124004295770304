<template>
	<div>
		<div v-if="shade" class="cover-layer invisible"></div>
		<div class="c-message flex-column flex-center an an-fadein" :class="{'an-fadeout': end}" @click="hide">
			<!-- <i v-if="iconClass" class="tf" :class="[iconClass]"></i> -->
			<p v-html="text"></p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'message',
		
		data() {
			return {
				timeout: 2500,
				icon: '',
				text: '',
				shade: false,
				end: false
			}
		},
		
		computed: {
			iconClass() {
				return {
					success: 'tf-success',
					fail: 'tf-fail'
				}[this.icon];
			}
		},

		created() {
			window.$messageObj && window.$messageObj.hide();
			this.timer = setTimeout(() => {
				this.hide();
			}, this.timeout);
			window.$messageObj = this;
		},

		methods: {
			hide() {
				delete window.$messageObj;
				this.end = true;
				clearTimeout(this.timer);
				setTimeout(() => {
					this.resolve && this.resolve();
					this.$destroy();
					this.$el.remove();
				}, 250);
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.c-message {
		z-index: 999;
		position: fixed;
		left: 50%;
		top: 50%;
		max-width: 15em;
		min-width: 8em;
		border-radius: 4px;
		padding: 14px 20px;
		color: #fff;
		background: rgba(0,0,0,.7);
		transform: translate(-50%, -50%);
		
		.tf {
			margin-bottom: 10px;
			font-size: 34px;
		}

		p {
			line-height: 20px;
			font-size: 14px;
			text-align: center;
			word-break: break-all;
		}
	}
</style>
