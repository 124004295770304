<template>
	<div style="display:none;"><slot :data="{}" :expand-data="{}"></slot></div>
</template>

<script>
	export default {
		name: 'cTableColumn',
		
		props: {
			type: {  // 列类型（selector：选择列；expand：展开列）
				type: String,
				default: 'normal'
			},
			
			fixed: [String, Boolean],  // 固定列
			
			label: String,  // 列名
			
			name: String,  // 字段名
			
			width: {  // 列宽
				type: [String, Number],
				default: 100
			},
			
			align: {
				type: String,
				default: 'left'
			},
			
			multiple: {  // 是否支持多选
				type: Boolean,
				default: true
			},
			
			limit: {  // 最大显示按钮数
				type: [Number, String],
				default: 3
			},
			
			expandButton: {  // 展开按钮
				type: Function,
				default: () => true
			},
			
			addButton: [Boolean, String, Function],  // 展开列新增子级按钮
			
			expandLimit: [String, Number],  // 展开列最大层级
			
			selected: Function  // 判断是否选中
		},
		
		created() {
			if (this.type == 'selector' && this.selected) {
				this.$parent.store.commit('initSelected', this.selected);
			}
		}
	};
</script>