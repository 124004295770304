<template>
	<div class="c-table-button">
		<div v-for="(item, i) in buttonList.list" :key="i" class="item">
			<a @click.stop="buttonHadnle(item)">{{item}}</a>
		</div>
		<div v-if="buttonList.more" ref="more" class="item" @mouseenter="more(true)" @mouseleave="more(false)">
			<a :class="{'z-hover': showMore}">更多<i class="tf tf-tri-down"></i></a>
		</div>
		
		<div v-if="buttonList.more" v-show="showMore" class="more-box" :class="[showMore]" :style="moreListPosition" @mouseenter="more(true)" @mouseleave="more(false)">
			<ul class="more">
				<li v-for="(item, i) in buttonList.more" :key="i" @click.stop="buttonHadnle(item)">{{item}}</li>
			</ul>
		</div>
		
		<div ref="box" style="display: none;"><slot></slot></div>
	</div>
</template>

<script>
	import $ from '@/assets/script/lib/jquery.js'
	
	export default {
		props: {
			updateFlag: Number,
			
			limit: {
				type: Number,
				default: 3
			}
		},
		
		data() {
			return {
				buttons: [],
				showMore: false,
				moreListPosition: {}
			}
		},
		
		watch: {
			updateFlag() {
				this.update();
			}
		},
		
		computed: {
			buttonList() {
				var list = Array.from(this.buttons, item => item), more = '';
				
				if (list.length > this.limit) {
					more = list.splice(this.limit - 1);
				}
				
				return {list, more}
			}
		},
		
		mounted() {
			this.update();
		},
		
		methods: {
			update() {
				this.$nextTick(() => {
					this.buttons = Array.from(this.$refs.box.children, (item, index) => {
						return item.innerText
					});
				});
			},
			
			more(state) {
				if (state) {
					clearTimeout(this.moreTimer);
					let listHeight = this.buttonList.more.length * 34 + 2;
					let topSeat = ($(window).height() - $(this.$refs.more).offset().top - $(this.$refs.more).innerHeight()) < (listHeight + 10);
					this.moreListPosition = {
						right: $(window).width() - $(this.$refs.more).offset().left - $(this.$refs.more).innerWidth() + 'px',
						top: (topSeat ? $(this.$refs.more).offset().top - listHeight - 10 : $(this.$refs.more).offset().top + $(this.$refs.more).innerHeight()) + 'px'
					}
					this.showMore = topSeat ? 'up' : 'down';
				} else {
					this.moreTimer = setTimeout(() => {
						this.showMore = false;
					}, 100)
				}
			},
			
			buttonHadnle(name) {
				this.$children.find(item => {
					return item.$slots.default[0].text == name;
				}).$emit('click');
			}
		}
	};
</script>