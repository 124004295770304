import Vue from 'vue';
import App from './App.vue';

import axios from 'axios';
import router from './router';
import store from './store';
import fun from './assets/script/function.js';

// Alpha [开发环境]; Online [正式环境]
const RE = 'Online';
const DEBUG = RE != 'Online';
const SERVER_ADDR = (re => {
    switch(re) {
        case 'Alpha':
            return 'https://szbh.web.hzyun.net';
        case 'Online':
            return 'https://dingapi.szbhjg.cn';
    }
})(RE);
const UPLOAD_ADDR = (re => {
    switch(re) {
        case 'Alpha':
            return 'https://szbhapi.web.hzyun.net';
        case 'Online':
            return 'https://api.szbhjg.cn';
    }
})(RE);

// 引入element-ui
import {DatePicker, ColorPicker} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(DatePicker);
Vue.use(ColorPicker);

// 挂载全局公共方法
import global from './assets/script/function';

// 挂载自定义组件
import customComp from './components';
Vue.use(customComp);

// 挂载动态组件
import dynamicComp from './components/dynamic';
Vue.use(dynamicComp);

import shortcuts from './assets/script/shortcuts'

Vue.prototype.$bus = new Vue();

// request请求方法
Vue.prototype.request = function(config) {
	config = Object.assign({
		method: 'post',
		data: {},
		success: () => {},
		fail: () => {},
		complete: () => {},
	}, config);
	
	const param = config.data;
	config.data.user_resource = 'ZZD';
	config.data.sessionId = fun.getCookie('session_id');

	if (config.method == 'get') {
		config.params = config.data;
		delete config.data;
	}

	config.loading && this.$loading(
		typeof config.loading == 'string' ? {
			text: config.loading
		} : {}
	);

	const source = axios.CancelToken.source();

	axios.create({
		baseURL: config.base == 'upload' ? UPLOAD_ADDR : SERVER_ADDR,
		cancelToken: source.token
	})(config).then(res => {
		config.loading && this.$loadOver();

		try {
			const data = res.data;
			DEBUG && console.group(`----- success ${config.url.split('?')[0]} -----`);
			DEBUG && console.log('@param', param);
			DEBUG && console.log('@return', data);

			try {
				if ((data.code == 405 || data.code == 403) && !config.ignoreTimeout) {
					// 登录超时
					this.loginTimeout();

					for (let i in axios.requestQueue) {
						axios.requestQueue[i].abort();
					}
					axios.requestQueue = {};
				} else {
					delete axios.requestQueue[request.index];

					if (data.code == 200 || (!data.code && data.state)) {
						// 请求成功
						config.success && config.success(data.obj, data.message);
					} else {
						// 请求失败
						if (!config.fail || config.fail(data.code, data.obj, data.message) !== false) {
							this.$message({
								text: data.message || '请求失败',
								icon: 'fail'
							});
						}
					}
				}
			} catch (e) {
				DEBUG && console.error(e);
			}
				
			config.complete && config.complete(data.code, data.obj, data.message);
			config.response && config.response(data);
		} catch(res) {
			DEBUG && console.group(`----- error ${config.url.split('?')[0]} -----`);
			DEBUG && console.log('@param', param);
			DEBUG && console.log('@return', res);

			if (!config.fail || config.fail() !== false) {
				this.$message({
					text: '接口数据异常',
					icon: 'fail'
				});
			};
		}
		DEBUG && console.log(`----- end ${config.url.split('?')[0]} -----`);
		DEBUG && console.groupEnd();
	}).catch(e => {
		config.loading && this.$loadOver();
		if (e.message == 'Network Error') {
			this.$store.commit('setNetworkError', true);
			return;
		}
		if (e.message == 'cancel') return;

		if (!config.error || config.error() !== false) {
			this.$message({
				text: '服务器出现未知异常',
				icon: 'fail'
			});
		};
	});

	if (!axios.requestQueue) {
		axios.requestIndex = 0;
		axios.requestQueue = {};
	}

	const request = {
		index: axios.requestIndex++,
		abort() {
			source.cancel('cancel');
		}
	};

	axios.requestQueue[request.index] = request;

	return request;
};

// 上传文件
Vue.prototype.uploadFile = function(opt) {
	app.$children[0].uploadFile(opt);
};

// 复制至剪贴板
Vue.prototype.clipboard = function(text, message) {
	app.$children[0].clipboard(text, message);
};

// 登录失效提示
Vue.prototype.loginTimeout = function() {
	if (!window.$loginTimeoutConfirm) {
		window.$loginTimeoutConfirm = true;
		this.$confirm({
			text: '登录状态已失效，请重新登录',
			buttonName: '重新登录',
			cancelButton: false,
			resolve: () => {
				delete window.$loginTimeoutConfirm;
				fun.removeCookie('session_id');
				app.$store.commit('setUserInfo', {});
				this.dingLogin();
			}
		});
	}
};

Vue.prototype.parsePixel = function(size) {
	if (parseInt(size) == size) {
		return parseInt(size) + 'px';
	}
	return size;
};

Vue.prototype.dingLogin = function(qrcode) {
	if (qrcode) {
		return `https://login-pro.ding.zj.gov.cn/oauth2/auth.htm?response_type=code&client_id=szbhjg_zzd_dingoa&redirect_uri=${SERVER_ADDR}/zzd/scan&scope=get_user_info&authType=QRCODE&embedMode=true`;
	}
	
	if (RE == 'Alpha' && true) {
		app.request({
			url: '/user/login',
			data: {
				user_id: '79358956'
			},
			success: data => {
				fun.setCookie('session_id', data.sessionId);
				app.$store.commit('setUserInfo', data);
			}
		});
	} else {
		dd.getAuthCode({}).then(res => {
			if (res) {
				app.request({
					url: '/zzd/login',
					data: {
						code: res.code || res.auth_code
					},
					success: data => {
						fun.setCookie('session_id', data.sessionId);
						app.$store.commit('setUserInfo', data);
					}
				});
			}
		}).catch(err => {
			if (fun.getCookie('session_id')) {
				app.$store.dispatch('updateUserInfo', (code, data) => {
					if ((code == 405 || code == 403) && !['/login', '/register'].includes(app.$route.path)) {
						app.navigateTo('/login');
					}
				});
			}
		});
	}
};

Vue.prototype.openFile = function(url) {
	if (app.$store.state.mobile) {
		dd.version().then(() => {
			this.$confirm({
				text: '系统提示',
				message: '手机端暂不支持附件预览，请前往电脑端查看',
				buttonName: '我知道了',
				cancelButton: false,
			});
		}).catch(() => {
			window.open(url);
		});
	} else {
		window.open(url);
	}
};

Vue.prototype.downloadFile = function(url) {
	let path = url.split('/');
	dd.downloadFile({
		url,
		name: path[path.length - 1]
	}).catch(() => {
		this.clipboard(url, false);
		this.$confirm({
			text: '系统提示',
			message: '文件下载失败，文件地址已复制到剪切板，请自行下载',
			buttonName: '我知道了',
			cancelButton: false,
		});
	});
};

Date.prototype.format = function(fmt) {
	var o = {
		'M+': this.getMonth() + 1, //月份
		'd+': this.getDate(), //日
		'h+': this.getHours(), //小时
		'm+': this.getMinutes(), //分
		's+': this.getSeconds(), //秒
		'q+': Math.floor((this.getMonth() + 3) / 3), //季度
		S: this.getMilliseconds(), //毫秒
	};

	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(
			RegExp.$1,
			(this.getFullYear() + '').substr(4 - RegExp.$1.length)
		);
	}

	for (var k in o) {
		if (new RegExp('(' + k + ')').test(fmt)) {
			fmt = fmt.replace(
				RegExp.$1,
				RegExp.$1.length == 1
					? o[k]
					: ('00' + o[k]).substr(('' + o[k]).length)
			);
		}
	}

	return fmt;
};

window.cDialogZIndex = 100;

Vue.config.productionTip = false;

const app = window.$app = new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
