<template>
	<div class="draw-item" :class="{'z-dis': disabled}" @mousedown="moveStart">
		<div ref="draw" class="content" :class="{'z-move': moveState}" :style="{'left': `${moveSeat.x}px`, 'top': `${moveSeat.y}px`}">
			<slot></slot>
			<div v-show="moveState" class="shade"></div>
		</div>
		<div v-show="moveState" class="place" :style="placeStyle"></div>
	</div>
</template>

<script>
	function getStyle(obj, attr) {
		if (obj.currentStyle) {
			return obj.currentStyle[attr];
		} else {
			return getComputedStyle(obj, false)[attr];
		}
	}
	
	function getPosition(elem){
		var elemTop = elem.offsetTop;
		var elemLeft = elem.offsetLeft;
		elem = elem.offsetParent;
		while (elem != null) {
			elemTop += elem.offsetTop - elem.scrollTop;
			elemLeft += elem.offsetLeft - elem.scrollLeft;
			elem = elem.offsetParent;
		}
		return {
			top: elemTop,
			left: elemLeft
		}
	}
	
	import global from '@/assets/script/function';
	
	export default {
		props: {
			index: [Number]
		},
		
		data() {
			return {
				moveState: false,
				
				moveSeat: {
					x: 0,
					y: 0
				},
				clientSeat: {
					x: 0,
					y: 0
				},
				
				placeStyle: {},
				
				disabled: this.$parent.disabled
			}
		},
		
		mounted() {
			this._contentDom = this.$refs.draw.children[0];
		},
		
		methods: {
			moveStart(e) {
				if (this.disabled) return;
				
				['width', 'height', 'margin-top', 'margin-bottom', 'margin-left', 'margin-right'].forEach(item => {
					this.$set(this.placeStyle, item, getStyle(this._contentDom, item));
				});
				
				this.moveState = true;
				this.updateMoveSeat(e);
				
				document.addEventListener('mousemove', this.move);
				document.addEventListener('mouseup', this.moveEnd);
			},
			
			move(e) {
				this.updateMoveSeat(e);
				this.updateSort();
			},
			
			moveEnd() {
				this.moveState = false;
				this.moveSeat.x = 0;
				this.moveSeat.y = 0;
				
				document.removeEventListener('mousemove', this.move);
				document.removeEventListener('mouseup', this.moveEnd);
				
				this.$parent.sortEnd();
			},
			
			updateMoveSeat(e) {
				this.moveSeat.x = e.clientX - parseInt(this.placeStyle['margin-left']) - (parseInt(this.placeStyle['width']) / 2);
				this.moveSeat.y = e.clientY - parseInt(this.placeStyle['margin-top']) - (parseInt(this.placeStyle['height']) / 2);
				this.clientSeat.x = e.clientX;
				this.clientSeat.y = e.clientY;
			},
			
			updateSort: global.throttle(function() {
				var list = this.$parent.$children;
				for (let i = 0; i < list.length; i++) {
					let item = list[i];
					
					if (item != this) {
						let position = getPosition(item.$el),
							minX = position.left,
							maxX = minX + parseInt(getStyle(item.$el, 'width')),
							minY = position.top,
							maxY = minY + parseInt(getStyle(item.$el, 'height'));
						
						if (this.clientSeat.x >= minX && this.clientSeat.x <= maxX && this.clientSeat.y >= minY && this.clientSeat.y <= maxY) {
							this.$parent.sortHandle(this.index, item.index);
							break;
						}
					}
				}
			}, 100, false)
		}
	}
</script>

<style lang="stylus" scoped>
	.draw-item {
		&:not(.z-dis) >>> > * {
			cursor: move;
		}
		
		.content {
			position: relative;
		
			&.z-move {
				z-index: 999;
				position: fixed;
			}
		
			.shade {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
		}
		
		.place {
			box-sizing: border-box;
			border: 1px dashed #666;
		}
	}
</style>
