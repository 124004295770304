<template>
	<div class="g-module-tab" :class="{'flex-column': flexColumn, 'flex': flexRow, 'flex-grow-auto': !columnScroll, 'flex-grow': columnScroll, 'z-active': activatedState}">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'moduleTab',
		
		props: {
			enableFlex: Boolean,
			
			title: String
		},
		
		data() {
			return {
				complexWarpper: false,
				columnScroll: false,
				mountedState: false,
				activatedState: false
			}
		},
		
		computed: {
			compEnableFlex() {
				return this.enableFlex || this.complexWarpper;
			},
			
			flexColumn() {
				return this.compEnableFlex && !this.complexWarpper;
			},
			
			flexRow() {
				return this.compEnableFlex && this.complexWarpper;
			}
		},
		
		created() {
			this.$parent.complexWarpper = true;
			this.$parent.insertTab(this);
		},
		
		methods: {
			resize() {
				this.$children.forEach(item => {
					if (['moduleArea', 'charts'].includes(item.$options.name)) {
						item.resize && item.resize();
					}
				});
				this.$emit('resize');
			},
			
			activated() {
				if (!this.mountedState) {
					this.mountedState = true;
					this.$emit('mounted');
				}
				this.activatedState = true;
				this.$emit('activated');
				this.resize();
			},
			
			deactivated(resolve, stop) {
				if (resolve) {
					this.activatedState = false;
				} else {
					this.$emit('deactivated', stop);
				}
			}
		}
	};
</script>