import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import fun from "../assets/script/function";

import routes from './routes';

Vue.use(VueRouter);

Vue.prototype.navigateTo = function(link, data, open) {
	if (typeof data == 'boolean') {
		open = data;
		data = undefined;
	}
	
	if (data) {
		var t = [];
		for (let key in data) {
			t.push(encodeURIComponent(key) + '=' + encodeURIComponent(typeof data[key] == 'object' ? JSON.stringify(data[key]) : data[key]));
		}
		link += '?' + t.join('&');
	}
	
	if (open) {
		let path = location.origin + location.pathname + location.search + this.$router.resolve(link).href;
		window.open(path);
	} else {
		this.$router.push(link);
	}
};
Vue.prototype.redirectTo = function(link) {
	window.$app.$children[0].$children[0].closeAlivePage();
	this.navigateTo(link);
};
Vue.prototype.navigateBack = function(n = -1) {
	this.$router.go(n);
};

const router = new VueRouter({
	mode: 'hash',
	base: '/',
	linkActiveClass: 'z-active',
	linkExactActiveClass: 'z-exact',
	routes
});

router.beforeEach((to, form, next) => {
	if (!fun.getCookie('session_id') && !['/login', '/register'].includes(to.path)) {
		dd.version().then(() => {
			next();
		}).catch(() => {
			next('/login');
		});
		return;
	}
	
	aplus_queue.push({
		'action': 'aplus.sendPV',
		'arguments': [{
			is_auto: false
		}, {
			sapp_id: '37490',
			sapp_name: 'szbhjg_zzd',
			page_id: to.meta.id,
			page_name: to.meta.title,
			page_url: location.href
		}]
	})
	
	next();
});

export default router