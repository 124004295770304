<template>
	<div class="button-box flex-reverse flex-center-cross">
		<div ref="button" class="button flex-reverse flex-center-cross">
			<slot></slot>
		</div>
		
		<c-button v-if="moreButton" color="gray" hollow class="item" @click="showMore">更多操作...</c-button>
		
		<div v-show="active" class="cover-layer an an-fadein" :class="{'an-fadeout': !show}" @click="hideMore"></div>
		<div class="button-more" :class="{'z-show': show}">
			<ul>
				<li v-for="(item, i) in moreButton" :key="i" class="item" @click="buttonHadnle(item)">{{item}}</li>
			</ul>
			<div class="item" @click="hideMore">取消</div>
		</div>
	</div>
</template>

<script>
	import $ from '@/assets/script/lib/jquery.js'
	
	export default {
		props: {
			limit: {
				type: [Number, String],
				default: 2
			}
		},
		
		data() {
			return {
				buttons: [],
				active: false,
				show: false
			}
		},
		
		computed: {
			moreButton() {
				var list = Array.from(this.buttons, item => item), more = '';
				
				if (list.length > this.limit) {
					more = list.splice(this.limit - 1);
				}
				
				return more;
			}
		},
		
		mounted() {
			this.buttons = Array.from(this.$refs.button.children, (item, index) => {
				return item.innerText
			});
		},
		
		methods: {
			showMore() {
				this.show = true;
				this.active = true;
			},
			
			hideMore() {
				this.show = false;
				setTimeout(() => {
					this.active = false;
				}, 250);
			},
			
			buttonHadnle(name) {
				this.$children.find(item => {
					return item.$options.name == 'cButton' && item.$slots.default[0].text == name;
				}).$emit('click');
				this.hideMore();
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.button {
		&-box {
			margin-top: .1rem;
		}
		
		.c-button {
			padding: 0 .2rem;
			margin-left: .16rem;
			
			&:nth-child(1) {
				display: block !important;
			}
			
			&:nth-child(n+3) {
				display: none;
			}
			
			&:nth-last-child(n+2) {
				display: none;
			}
		}
		
		&-more {
			z-index: 101;
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: .08rem .08rem 0 0;
			overflow: hidden;
			background: #F2F2F2;
			transform: translate(0,100%);
			transition: transform .3s ease;
			
			&.z-show {
				transform: translate(0,0);
			}
			
			ul {
				padding-top: .24rem;
				margin-bottom: .2rem;
				background: #fff;
			}
			
			.item {
				line-height: 1.06rem;
				font-size: .36rem;
				text-align: center;
				color: #1F1F1F;
				background: #fff;
				
				&:nth-child(n+2) {
					border-top: 1px solid #eee;
				}
			}
		}
	}
</style>