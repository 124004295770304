<template>
	<div v-show="!fadein || active">
		<div v-show="active" class="cover-layer an an-fadein" :class="{'an-fadeout': !show}" :style="{'z-index': zIndex}"></div>
		<div ref="wrapper" class="c-dialog-wrapper an" :class="{'s-slide': !fadein, 'z-show': show, 'an-fadein': fadein, 'an-fadeout': fadein && !show}" :style="{'z-index': zIndex, 'transform': translate}">
			<div class="c-dialog an" :class="{'an-bounce': fadein, 'confirm': type == 'confirm'}" :style="{'width': compWidth}">
				<div v-if="titleText !== false" class="c-dialog-head">
					<p>{{titleText}}</p>
				</div>
				<div v-if="closeButton" class="c-dialog-close flex-center" @click="close"><i class="tf tf-cross"></i></div>
				<div class="c-dialog-content" :style="{'height': compHeight, 'overflow': fadein && height == 'auto' ? '' : 'auto'}">
					<slot></slot>
				</div>
				<div v-if="button" class="c-dialog-button flex-reverse flex-center-cross">
					<slot v-if="$slots.button" name="button"></slot>
					<template v-else>
						<c-button @click="buttonHandle('resolve')">{{buttonName}}</c-button>
						<c-button v-if="cancelButton" color="gray" hollow @click="buttonHandle('cancel')">{{cancelButtonName}}</c-button>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			fadein: Boolean,
			
			type: {
				type: String,
				default: ''
			},
			
			title: {
				type: [String, Boolean],
				default: ''
			},

			width: {
				type: [Number, String],
				default: 750
			},

			height: {
				type: [Number, String],
				default: 'auto'
			},

			closeButton: {
				type: Boolean,
				default: true
			},

			button: {
				type: Boolean,
				default: true
			},
			
			buttonName: {
				type: String,
				default: '确定'
			},

			cancelButton: {
				type: Boolean,
				default: true
			},
			
			cancelButtonName: {
				type: String,
				default: '取消'
			}
		},

		data() {
			return {
				zIndex: 0,
				active: false,
				show: false,
				translate: '',
				titleText: this.title
			}
		},

		computed: {
			compWidth() {
				if (this.width == parseInt(this.width)) {
					let maxWidth = window.innerWidth - 60;
					let width = parseInt(this.width);
					return `${width > maxWidth ? maxWidth : width}px`;
				}
				return this.width;
			},

			compHeight() {
				if (this.height == 'auto') {
					return 'auto';
				} else if (this.height.includes('%')) {
					return `${document.body.offsetHeight * parseInt(this.height) / 100}px`;
				} else {
					let maxHeight = window.innerHeight - 200;
					let height = parseInt(this.height);
					return `${height > maxHeight ? maxHeight : height}px`;
				}
			}
		},
		
		watch: {
			title(val) {
				this.titleText = val;
			}
		},

		methods: {
			open(option = {}) {
				option.title && (this.titleText = option.title);
				this.zIndex = window.cDialogZIndex++;
				this.show = true;
				this.active = true;
				
				if (this.fadein) {
					setTimeout(() => {
						this.translate = `translate(-${parseInt(this.compWidth) / 2}px, -${(this.$refs.wrapper.getBoundingClientRect().height / 2).toFixed(0)}px)`
					}, 400);
				}
			},

			close() {
				this.show = false;
				setTimeout(() => {
					this.active = false;
				}, 250);
				this.$emit('close');
			},

			buttonHandle(state) {
				let stop = false;
				this.$emit(state, () => {
					stop = true;
				}, () => {
					this.close();
				});
				!stop && this.close();
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.c-dialog {
		width: 750px;
		background: #fff;
		
		&.confirm {
			.c-dialog-head {
				line-height: 44px;
				border-bottom: none;
				
				p {
					font-size: 15px;
					text-indent: 18px;
				}
			}
			
			.c-dialog-button {
				height: auto;
				border: none;
				padding: 20px 32px 24px;
				
				button {
					min-width: 72px;
				}
			}
		}
		
		&-wrapper {
			position: fixed;
			left: 50%;
			top: 50%;
			z-index: 102;
			transform: translate(-50%, -50%);
			
			&.s-slide {
				left: auto;
				right: 0;
				top: 0;
				bottom: 0;
				transform: translate(100%, 0);
				transition: transform .4s ease;
				
				&.z-show {
					transform: translate(0, 0);
				}
				
				.c-dialog {
					display: flex;
					flex-direction: column;
					height: 100%;
					
					&-content {
						flex-grow: 1;
						height: auto;
					}
				}
			}
		}

		&-head {
			line-height: 54px;
			border-bottom: 1px solid #eee;
			
			p {
				height: 54px;
				line-height: 60px;
				font-size: 16px;
				font-weight: bold;
				text-indent: 20px;
			}
		}

		&-close {
			z-index: 1;
			position: absolute;
			right: 0;
			top: 0;
			width: 54px;
			height: 54px;
			font-size: 16px;
			color: #999;
			cursor: pointer;
			
			&:hover{
				color: #000;
			}
		}

		&-button {
			height: 60px;
			border-top: 1px solid #EEEEEE;
			padding: 0 20px;
			
			button {
				padding: 0 20px;
				margin-left: 12px;
			}
		}
		
		.c-hint {
			border-width: 0 0 1px;
			border-radius: 0;
		}
		
		.c-form {
		    padding: 34px 30px;
		}
	}
</style>
