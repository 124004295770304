<template>
	<div class="c-file" :class="{'flex-start-end': media, 's-media': media}">
		<div v-if="media" class="c-file-media flex-wrap">
			<div v-if="!fileList.length" class="c-file-media-item flex-center empty" @click="uploadHandle">
				<i class="tf" :class="`tf-${media}`"></i>
			</div>
			<div v-for="(item, i) in fileList" :key="i" class="c-file-media-item flex-center">
				<video v-if="media == 'video'" :src="item"></video>
				<img v-else :src="item">
				<a class="flex-center" @click="remove(i)" @mousedown.stop><i class="tf tf-cross"></i></a>
			</div>
		</div>
		
		<div :class="{'flex-column': media, 'flex-center-cross': !media}">
			<div class="c-file-button flex-center-cross">
				<c-button color="gray" icon="upload" hollow @click="uploadHandle">{{button}}</c-button>
			</div>
			<div v-if="$slots.word || word || fileTypeText" class="c-file-word">
				<span v-if="$slots.word"><slot name="word"></slot></span>
				<span v-else>{{word}}</span>
				<span v-if="fileTypeText">支持格式：{{fileTypeText.join('，')}}</span>
			</div>
		</div>
		
		<ul v-if="!media" v-show="fileList.length">
			<li v-for="(item, i) in fileList" :key="i"><span>{{fileName(item)}}</span><a @click="remove(i)">[删除]</a></li>
		</ul>
	</div>
</template>

<script>
	export default {
		props: {
			type: String,
			
			size: [String, Number],
			
			media: String,
			
			button: {
				type: String,
				default: '上传文件'
			},
			
			word: String,
			
			value: [String, Array],
			
			limit: {
				type: Number,
				default: 1
			}
		},

		data() {
			return {
				fileList: []
			}
		},

		computed: {
			fileType() {
				if (this.type) {
					let arr = [];
					this.type.split(',').forEach(item => {
						arr = arr.concat({
							image: ['jpg', 'jpeg', 'png'],
							video: ['mp4'],
							word: ['doc', 'docx'],
							excel: ['xls', 'xlsx'],
							zip: ['zip', '7z', 'rar']
						}[item] || [item])
					});
					return arr;
				}
				return '';
			},
			
			fileTypeText() {
				if (this.type) {
					let arr = [];
					this.type.split(',').forEach(item => {
						arr = arr.concat({
							image: ['图片'],
							video: ['mp4'],
							word: ['doc(x)'],
							excel: ['xls(x)'],
							zip: ['压缩包']
						}[item] || [item])
					});
					return arr;
				}
				return '';
			},
			
			fileName() {
				return path => {
					path = path.split('/');
					return path[path.length - 1];
				}
			},
			
			spare() {
				return this.fileList.length < this.limit;
			}
		},

		watch: {
			value() {
				this.update();
			},
			
			fileList(val) {
				this.$emit('input', this.limit == 1 ? (val[0] || '') : val);
			}
		},
		
		mounted() {
			this.update();
		},

		methods: {
			update() {
				this.fileList = this.value ? (typeof this.value == 'object' ? this.value : this.value.toString().split(',')) : [];
			},
			
			remove(i) {
				this.fileList.splice(i, 1);
			},

			uploadHandle() {
				this.uploadFile({
					fileType: Array.from(this.fileType, item => '.' + item).join(','),
					fileSize: this.size,
					multiple: parseInt(this.limit) > 1,
					success: data => {
						if (this.limit == 1) {
							this.fileList = data;
						} else {
							this.fileList = this.fileList.concat(data);	
						}
					}
				});
			}
		}
	}
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/mobile'
	
	.c-file {
		&:not(.s-media) {
			.c-file-button {
				height: 38px;
				margin-right: 8px;
				
				elder({
					height: 46px
				})
			}
		}
		
		&.s-media {
			.c-file-word {
				margin: 6px 0 2px;
			}
		}
		
		&-media {
			margin-top: -15px;
	
			&-item {
				position: relative;
				box-sizing: border-box;
				width: 80px;
				height: 80px;
				border: 1px solid #DDDDDD;
				margin-right: 15px;
				margin-top: 15px;
				background: #fff;
				box-sizing: border-box;
				
				&.empty {
					font-size: 30px;
					color: #aaa;
					cursor: pointer;
					
					&:hover {
						border-color: #1492FF;
						color: #1492FF;
					}
				}
				
				video {
					width: 100%;
					height: 100%;
					background: #000;
				}
				
				a {
					position: absolute;
					right: 0;
					top: 0;
					width: 18px;
					height: 18px;
					font-size: 12px;
					color: #fff;
					background: rgba(0,0,0,.6);
				}
			}
		}
		
		&-word {
			line-height: 20px;
			font-size: 13px;
			color: #999;
		}
		
		ul {
			margin-top: 4px;
			
			li {
				line-height: 24px;
				font-size: 12px;
				color: #0030FF;
				
				a {
					margin-left: 12px;
					color: #f00;
				}
			}
		}
	}
</style>
