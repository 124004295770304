<template>
    <div class="c-pagination flex-end-center">
		<div class="item" :class="{'z-dis': pageIndex == 1}" @click="update(pageIndex - 1)"><i class="tf tf-angle-left"></i></div>
		<div v-for="(item, i) in pageList" :key="i" class="item" :class="{'z-crt': pageIndex == item, 'z-ellipsis': !item}" @click="update(item)">{{item || '...'}}</div>
		<div class="item" :class="{'z-dis': pageIndex == pageCount}" @click="update(pageIndex + 1)"><i class="tf tf-angle-right"></i></div>
    </div>
</template>

<script>
	export default {
        props: {
            total: {
                type: [String, Number],
                default: 0
            }
        },
        
        data() {
            return {
                pageIndex: 1,
                pageSize: 20,
                pageSizeOptions: [{
                    name: '10条/页',
                    value: 10
                }, {
                    name: '20条/页',
                    value: 20
                }, {
                    name: '50条/页',
                    value: 50
                }, {
                    name: '100条/页',
                    value: 100
                }]
            }
        },
		
		computed: {
			pageCount() {
				return Math.ceil(parseInt(this.total) / this.pageSize) || 1;
			},
			
			pageList() {
				var arr = [];
				for (let i = 1; i <= this.pageCount; i++) {
					if (
						this.pageCount <= 9 ||
						(i == 1 || i == this.pageCount) ||
						(this.pageIndex < 6 && i <= 7) ||
						(this.pageIndex > this.pageCount - 5 && i >= this.pageCount - 6) ||
						(i >= this.pageIndex - 2 && i <= this.pageIndex + 2)
					) {
						arr.push(i);
					} else if (arr[arr.length - 1] != '') {
						arr.push('');
					}
				}
				return arr;
			}
		},
        
        watch: {
            pageSize() {
				this.update(1);
            }
        },
        
		methods: {
            update(page) {
				page = parseInt(page || this.pageIndex) || 1;
				page = page < 1 ? 1 : page > this.pageCount ? this.pageCount : page;
                
                this.pageIndex = page;
                this.$emit('handle', {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                });
            }
		}
	};
</script>

<style lang="stylus" scoped>
	.c-pagination {
		position: relative;
		height: 54px;
		padding: 0 18px;
		
		.item {
			box-sizing: border-box;
			width: 30px;
			border: 1px solid #ddd;
			border-radius: 2px;
			line-height: 28px;
			font-size: 14px;
			text-align: center;
			color: #000;
			background: #fff;
			cursor: pointer;
			
			&:nth-child(n+2) {
				margin-left: 8px;
			}
				
			&:not(.z-dis):not(.z-ellipsis):hover,
			&.z-crt {
				border-color: #1492FF;
				color: #1492FF;
			}
			
			&.z-ellipsis,
			&.z-dis {
				color: #ccc;
				cursor: not-allowed;
			}
			
			&.z-ellipsis {
				color: #000;
			}
		}
	}
</style>
