var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"c-button",class:[{'border': _vm.hollow, 'z-dis': _vm.disabled}, `s-${_vm.color}`],style:({
			'min-width': _vm.compWidth,
			'height': _vm.compHeight,
			'border-color': _vm.background,
			'border-radius': _vm.compRadius,
			'font-size': _vm.compFontSize,
			'color': _vm.fontColor,
			'background': _vm.background
		}),attrs:{"disabled":!!_vm.disabled},on:{"click":$event => _vm.$emit('click', $event)}},[_c('div',{staticClass:"flex-center"},[(_vm.iconClass)?_c('i',{staticClass:"tf",class:[_vm.iconClass]}):_vm._e(),_c('span',[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }