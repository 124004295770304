import empty from '@/components/public/empty.vue'
import {mapState} from './store/index.js'

function renderExpand(h, parent) {
	if (!parent.expandData.children) return [];
	
	var arr = [];
	parent.expandData.children.concat(parent.expandData.add ? [parent.expandData.add] : []).map((row, i) => {
		arr = arr.concat([
			<tr style={`display:${row.expandData.hidden ? 'none': 'table-row'}; background:${row.type == 'expandButton' ? '#fafafa' : '#fff'}`}>
				{
					renderCell.call(this, h, row, i)
				}
			</tr>
		].concat(renderExpand.call(this, h, row)));
	});
	
	return arr;
}

function renderCell(h, row, i) {
	if (row.type == 'expandButton') {
		return [
			h('td', {
				attrs: {
					colspan: this.columns.length
				}
			}, row.renderCell(row))
		]
	} else {
		return this.columns.map((col, j) => {
			row.index = i;
			return [
				h('td', col.renderCell(row))
			]
		})
	}
}

export default {
	render(h) {
		return (
			<div class="c-table-body-wrapper flex-grow" style={`left: ${this.left}; width: ${this.compWidth}; height: ${this.autoHeight ? 'auto' : ''};`}>
				{
					this.showEmpty ? <empty></empty> : ''
				}
				<table style={`min-width:${this.columnsWidth.all}px; display:${this.showEmpty ? 'none' : 'table'};`}>
					<colgroup>
						{
							this.columns.map((col, i) => <col width={col.width} />)
						}
					</colgroup>
					<tbody>
						{
							this.dataList.map((row, i) => {
								return [
									<tr class={`${row.hover ? 'z-hover' : ''} ${this.rowClick ? 'z-pointer' : ''} ${this.currentRow == row || row.selected ? 'z-hl' : ''}`} on-mouseenter={$event => this.rowHover(row, true)} on-mouseleave={$event => this.rowHover(row, false)} on-click={$event => this.rowClickHandle(row, $event)}>
										{
											renderCell.call(this, h, row, i)
										}
									</tr>
								].concat(renderExpand.call(this, h, row))
							})
						}
					</tbody>
				</table>
			</div>
		)
	},
	
	components: {
		empty
	},
	
	props: {
		store: Object,
			
		autoHeight: Boolean,
		
		fixed: String,
        
        left: String
	},
	
	computed: {
		...mapState(['dataList', 'columns', 'columnsWidth', 'emptyState', 'rowClick', 'currentRow']),
		
		compWidth() {
			return this.fixed ? `${this.columnsWidth.all}px` : '100%';
		},
			
		showEmpty() {
			return this.emptyState && !this.fixed;
		}
	},
	
	methods: {
		rowHover(row, state) {
			row.hover = state;
		},
		
		rowClickHandle(row) {
			if (this.rowClick) {
				this.store.commit('currentRow', row);
				this.store.table.$emit('row-click', row);
			}
		}
	}
};