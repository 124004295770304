<template>
	<div v-show="type != 'hidden'" class="c-form-item flex" :class="{'flex-column': mobile}" :style="{'width': compWidth}">
		<label v-if="label !== false" class="c-form-item-label flex-end-center" :style="{'align-self': mobile ? 'start' : '', 'min-width': mobile ? 0 : `${labelLength}em`}"><b v-if="required">*</b>{{label}}</label>
		<div class="c-form-item-content flex-grow-auto flex-wrap">
			<div v-if="type" class="c-form-item-unit flex-grow" :class="[unitType]" :style="{[searchForm ? 'width' : 'max-width']: compUnitWidth}">
				<c-input v-if="unitType == 'input'" v-model="unitData" :type="type" width="100%" :placeholder="placeholder" :maxlength="maxlength" :decimal="decimal" :limit="limit" :disabled="disabled" :readonly="readonly" :word="inputWord" @click="$emit('click')" @change="$emit('change', unitData)"></c-input>
			
				<div v-else-if="unitType == 'text'">{{unitData}}</div>
			
				<textarea v-else-if="unitType == 'textarea'" v-model="unitData" :placeholder="placeholder" :maxlength="maxlength" @focus="focusState = true" @blur="focusState = false"></textarea>
			
				<c-radio-group v-else-if="unitType == 'radio'" v-model="unitData">
					<c-radio v-for="(item, i) in optionsList" :key="i" :value="item.value" :skin="radioSkin">{{item.name}}</c-radio>
				</c-radio-group>
							
				<c-checkbox-group v-else-if="unitType == 'checkbox'" v-model="unitData">
					<c-checkbox v-for="(item, i) in optionsList" :key="i" :value="item.value">{{item.name}}</c-checkbox>
				</c-checkbox-group>
				
				<c-select v-else-if="unitType == 'select'" v-model="unitData" :options="optionsList" :multiple="multiple" :search="search" :disabled="disabled" @search="$emit('search', arguments[0], arguments[1])"></c-select>
				
				<c-datetime v-else-if="unitType == 'datetime'" v-model="unitData" :type="dateType" :range="dateTimeRange" :placeholder="placeholder"></c-datetime>
				
				<c-color v-else-if="unitType == 'color'" v-model="unitData" :placeholder="placeholder"></c-color>
				
				<c-link v-else-if="unitType == 'link'" v-model="unitData"></c-link>
				
				<c-upload-file v-else-if="unitType == 'file'" v-model="unitData" :type="type == 'file' ? fileType : type" :media="type == 'file' ? '' : type" :word="fileWord" :limit="unitLimit"></c-upload-file>

				<c-editor v-else-if="unitType == 'editor'" ref="editor" :content="unitData"></c-editor>
				
				<div v-else-if="unitType == 'region'">
					<div class="region-select flex">
						<div class="flex-grow">
							<c-select v-model="regionProvince" :options="provinceOptions" :disabled="disabled"></c-select>
						</div>
						<div class="flex-grow">
							<c-select v-model="regionCity" :options="cityOptions" :disabled="disabled"></c-select>
						</div>
						<div class="flex-grow">
							<c-select v-model="regionCounty" :options="countyOptions" :disabled="disabled"></c-select>
						</div>
					</div>
					<div v-if="addressName" class="region-input">
						<c-input v-model="regionAddress" width="100%" placeholder="详细街道地址" :disabled="disabled"></c-input>
					</div>
				</div>
			</div>

			<div v-else class="c-form-item-unit custom flex-grow">
				<slot></slot>
			</div>
			
			<div v-if="word || $slots.word" class="c-form-item-word" :style="{'width': inlineWord ? 'auto' : '100%'}">
				<i class="tf tf-tips"></i>
				<div>
					<p>
						{{word}}
						<slot name="word"></slot>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from './store/index.js'
	
	export default {
		name: 'cFormItem',
		
		props: {
			width: [String, Number],
			
			unitWidth: [String, Number],
			
			type: String,  // 表单项类型
			
			label: {  // 表单项名称
				type: [String, Boolean],
				default: ''
			},
			
			name: [String, Number],  // 字段名
			
			startName: String,  // 时期范围起始日期字段名
			
			endName: String,  // 时期范围结束日期字段名
			
			provinceName: String,  // 省份字段名
			
			cityName: String,  // 城市字段名
			
			countyName: String,  // 区县字段名
			
			addressName: String,  // 详细地址字段名

			required: Boolean,  // 是否必填
			
			disabled: Boolean,  // 是否禁用

			value: {  // 表单默认值
				type: [String, Number, Boolean, Array, Object],
				default: ''
			},

			placeholder: {  // 未填写时的说明
				type: String,
				default: ''
			},
			
			readonly: Boolean,  // 只读
			
			word: String,  // 说明文字
			
			inlineWord: {  // 说明文字是否行内显示
				type: Boolean,
				default: true
			},
			
			maxlength: [Number, String],  // 文本框最大长度
			
			decimal: [Number, String],  // 数字文本框小数位数

			options: {  // 选择项
				type: [Array, String],
				default: () => {
					return [];
				}
			},
			
			multiple: Boolean,  // 下拉框是否支持多选
			
			search: Boolean,  // 下拉框是否支持搜索
			
			dateType: {  // 时间选择器类型
				type: String,
				default: 'datetime'
			},
			
			gallery: Boolean,  // 媒体组件是否使用图片空间选择
			
			inputWord: String,
			
			fileWord: String,  // 媒体、文件的描述
			
			fileType: String,  // 文件类型
			
			limit: [String, Number, Array],  // 内容限制
			
			radioSkin: String  // 单选框样式
		},
		
		data() {
			return {
				focusState: false,
				unitData: this.value || (this.type == 'checkbox' ? [] : ''),
				
				optionsList: [],
				
				regionProvince: '',
				regionCity: '',
				regionCounty: '',
				regionAddress: '',
				
				provinceOptions: [],
				cityOptions: [],
				countyOptions: [],
				
				mobile: this.$store.state.mobile
			};
		},
		
		computed: {
			...mapState(['searchForm', 'defaultItemWidth', 'defaultUnitWidth', 'labelLength']),
			
			compWidth() {
				return this.parsePixel(this.width || this.defaultItemWidth);
			},
			
			compUnitWidth() {
				if (this.searchForm && (this.type == 'datetime' || this.type == 'region') && this.dateTimeRange) {
					return (parseInt(this.labelLength) * 13) + 10 + (parseInt(this.defaultUnitWidth) * 2) + 10 + 20 + 'px';
				}
				
				if (!['radio', 'checkbox', 'color', 'image', 'video', 'file'].includes(this.type)) {
					return this.parsePixel(this.unitWidth || this.defaultUnitWidth);
				}
				
				return 'auto';
			},
			
			isUpload() {
				return ['image', 'video', 'file'].includes(this.type);
			},
			
			unitType() {
				if (['input', 'password', 'hidden', 'number'].includes(this.type)) {
					return 'input';
				}
				if (['image', 'video'].includes(this.type)) {
					return 'file';
				}
				return this.type;
			},
			
			unitLimit() {
				if (this.isUpload) {
					return parseInt(this.limit) || 1;
				}
				return this.limit;
			},
			
			dateTimeRange() {
				return !!(this.startName || this.endName);
			},
			
			regionData() {
				return {
					province: this.regionProvince,
					city: this.regionCity,
					county: this.regionCounty,
					address: this.regionAddress
				}
			}
		},
		
		watch: {
			value(val) {
				this.setData(val);
			},
			
			unitData(val) {
				this.$emit('input', val);
			},
			
			options(val) {
				this.getOptions();
			},
			
			regionProvince(val) {
				if (val) {
					val && this.getRegionOptions('city', val);
				} else {
					this.cityOptions = [];
					this.countyOptions = [];
					this.regionCity = this.regionCounty = '';
				}
			},
			
			regionCity(val) {
				if (val) {
					this.getRegionOptions('county', val);
				} else {
					this.countyOptions = [];
					this.regionCounty = '';
				}
			},
			
			regionData(val) {
				this.$emit('input', val);
			}
		},
		
		created() {
			this.store = this.$parent.store;
			this.store.setLabelLength((this.label ? this.label.length : 0) + (this.required ? 1 : 0));
		},
		
		mounted() {
			if (['radio', 'checkbox', 'select'].includes(this.type)) {
				this.getOptions();
			} else if (this.type == 'region') {
				this.getRegionOptions('province');
			}
		},

		methods: {
			getOptions() {
				if (typeof this.options == 'string') {
					new Promise(resolve => {
						switch (this.options) {
						// 所属行业
						case 'INDUSTRY':
							resolve(this.$store.state.hangyeList);
							break;
							
						// 保函类型
						case 'INSURANCE_TYPE':
                            this.request({
                                url: '/bx_type/list',
                                success: data => {
                                    resolve(Array.from(data, item => {
                                        return {
                                            name: item.type_name,
                                            value: item.type_id
                                        }
                                    }));
                                }
                            });
							break;
							
						default:
							resolve(this.options);
						}
					}).then(data => {
						this.optionsList = data;
					});
				} else {
					this.optionsList = this.options;
				}
			},
			
			getRegionOptions(type, pid = 0) {
				this.request({
					url: '/dic/region_option_list',
					data: {
						type: type,
						parent_id: pid
					},
					success: data => {
						this[`${type}Options`] = Array.from(data, item => {
							return {
								name: item.name,
								value: item.id
							}
						});
						
						this.$nextTick(() => {
							if (this._regionInit) {
								type == 'province' && (this.regionProvince = this._regionInit.province);
								type == 'city' && (this.regionCity = this._regionInit.city);
								type == 'county' && (this.regionCounty = this._regionInit.county) && (delete this._regionInit);
							}
						});
					}
				});
			},
			
			getData() {
				switch (this.type) {
				case 'region':
					return this.regionData;
				
				case 'editor':
					return this.$refs.editor.getContent();
					
				default:
					return this.unitData;
				}
			},
			
			setData(val) {
				if (this.type == 'region') {
					this._regionInit = val;
					if (this.provinceOptions.length) {
						this.regionProvince = this._regionInit.province;
					}
					this.regionAddress = this._regionInit.address;
				} else {
					this.unitData = val;
				}
			},

			clear() {
				switch (this.type) {
				case 'checkbox':
					this.unitData = [];
					break;
					
				case 'region':
					this.regionProvince = this.regionCity = this.regionCounty = this.regionAddress = '';
					break;
					
				default:
					this.unitData = '';
				}
			}
		}
	};
</script>