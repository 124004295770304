<template>
	<div class="c-detail">
		<c-title v-if="title">{{title}}</c-title>
		<div v-if="$slots.default" class="c-detail-content flex-wrap">
			<slot></slot>
		</div>
		<div class="c-detail-custom">
			<slot name="custom"></slot>
		</div>
		<div v-if="$slots.button" class="flex-center c-detail-button">
			<slot name="button"></slot>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			title: String
		}
	}
</script>

<style lang="stylus">
	@import '~@/assets/style/mobile'
	
	.c-detail {
		padding: 0 30px 20px;
		
		mobile({
			padding: 0,
			background: #fff
		})
	
		&:nth-child(1) {
			padding-top: 30px;
		
			mobile({
				padding-top: 0
			})
		}
		
		&:nth-child(n+2) {
			mobile({
				margin-top: .2rem
			})
		}
		
		&-content {
			border-right: 1px solid #E8E7E8;
			border-top: 1px solid #E8E7E8;
			
			mobile({
				border-right: none
			})
			
			&:after {
				content: '';
				display: block;
				clear: both;
			}
		}

		&-item {
			float: left;
			box-sizing: border-box;
			border-left: 1px solid #E8E7E8;
			border-bottom: 1px solid #E8E7E8;
			
			mobile({
				border-left: none
			})
			
			&-label {
				width: 200px;
				border-right: 1px solid #E8E7E8;
				padding: 16px 18px;
				line-height: 20px;
				font-size: 15px;
				color: #000;
				background: #F4FAFE;
		
				mobile({
					box-sizing: border-box,
					width: 2.72rem,
					padding: .2rem .2rem,
					line-height: .4rem,
					font-size: .3rem,
					color: #828386
				})

				b {
					width: 1em;
					text-align: center;
					color: #f55;
				}
			}
			
			&-content {
				padding: 16px 12px;
				line-height: 20px;
				font-size: 15px;
				color: #000;
		
				mobile({
					padding: .2rem .2rem,
					line-height: .4rem,
					font-size: .3rem
				})
			}
		}
		
		&-custom {
			&:after {
				content: '';
				display: block;
				height: 1px;
				margin-top: -1px;
			}
		}
	
		&-button {
			margin-top: 40px;
			
			.c-button {
				margin: 0 9px;
			}
		}
	}
</style>
