<template>
	<draw-sort v-model="fileList" :disabled="limit == 1">
		<template v-slot="{list}">
			<div class="c-media flex-wrap flex-end-cross">
				<draw-item v-for="(item, i) in list" :key="item" :index="i">
					<div class="c-media-item flex-center" :class="{'last': limit == 1}" :style="{'width': `${size}px`, 'height': `${size}px`}">
						<video v-if="type == 'video'" :src="item"></video>
						<img v-else :src="item">
						<a class="flex-center" @click="remove(i)" @mousedown.stop><i class="tf tf-cross"></i></a>
					</div>
				</draw-item>
				
				<div v-show="spare" class="c-media-item c-media-upload flex-center" :style="{'width': `${size}px`, 'height': `${size}px`}" @click="uploadHandle">
					<i class="tf tf-plus"></i>
				</div>
					
				<div v-if="word" v-html="word" class="c-media-word"></div>
			</div>
		</template>
	</draw-sort>
</template>

<script>
	import drawSort from '@/components/draw_sort/index.vue'
	import drawItem from '@/components/draw_sort/item.vue'
	
	export default {
		components: {
			drawSort, drawItem
		},

		props: {
			type: {
				type: String,
				default: 'image'
			},
			
			value: [String, Array],
            
            size: {
                type: [String, Number],
                default: 90
            },
			
			limit: {
				type: Number,
				default: 1
			},
			
			word: String
		},

		data() {
			return {
				fileList: []
			}
		},

		computed: {
			spare() {
				return this.fileList.length < this.limit;
			}
		},

		watch: {
			value() {
				this.update();
			},
			
			fileList(val) {
				this.$emit('input', this.limit == 1 ? (val[0] || '') : val);
			}
		},
		
		mounted() {
			this.update();
		},
		
		methods: {
			update() {
				this.fileList = this.value ? (typeof this.value == 'object' ? this.value : this.value.toString().split(',')) : [];
			},
			
			remove(i) {
				this.fileList.splice(i, 1);
			},
			
			uploadHandle() {
				this.uploadFile({
					fileType: `${this.type}/*`,
					multiple: parseInt(this.limit) > 1,
					success: data => {
						let n = this.limit - this.fileList.length;
						this.fileList = this.fileList.concat(data.splice(0, n));
					}
				});
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.c-media {
		margin-top: -15px;

		&-item {
			position: relative;
			border: 1px solid #DDDDDD;
			margin-right: 15px;
			margin-top: 15px;
			background: #fff;
			box-sizing: border-box;
            
            &.last,
            &.c-media-upload {
                margin-right: 0;
            }
			
			video {
				width: 100%;
				height: 100%;
				background: #000;
			}
			
			a {
				position: absolute;
				right: 0;
				bottom: 0;
				width: 18px;
				height: 18px;
				font-size: 12px;
				color: #fff;
				background: #FF1010;
			}
		}
		
		&-upload {
			font-size: 20px;
			color: #ccc;
			cursor: pointer;
			transition: all .3s ease;
			
			&:hover {
				border-color: #bbb;
				color: #bbb;
			}
		}
		
		&-word {
			margin-top: 9px;
            margin-left: 15px;
			line-height: 20px;
			font-size: 13px;
			color: #999;
		}
	}
</style>
