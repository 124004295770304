import page from './public/page.vue'
import moduleGroup from './public/module_group.vue'
import module from './public/module.vue'
import moduleTab from './public/module_tab.vue'
import moduleColumn from './public/module_column.vue'

import cTable from './table/index.vue'
import cTableColumn from './table/column.vue'
import cTableButton from './table/button.vue'

import cForm from './form/index.vue'
import cFormColumn from './form/column.vue'
import cFormItem from './form/item.vue'

import cDetail from './detail/index.vue'
import cDetailItem from './detail/item.vue'

import cTitle from './unit/title.vue'
import cHint from './unit/hint.vue'
import cInput from './unit/input.vue'
import cButton from './unit/button.vue'
import cRadio from './unit/radio.vue'
import cRadioGroup from './unit/radio_group.vue'
import cCheckbox from './unit/checkbox.vue'
import cCheckboxGroup from './unit/checkbox_group.vue'
import cSelect from './unit/select.vue'
import cDatetime from './unit/datetime.vue'
import cColor from './unit/color.vue'
import cLink from './unit/link.vue'
import cMedia from './unit/media.vue'
import cFile from './unit/file.vue'
import cEditor from './unit/editor.vue'
import cSwitch from './unit/switch.vue'
import cUploadMedia from './unit/upload_media.vue'
import cUploadFile from './unit/upload_file.vue'

import cDialog from './dialog/index.vue'

import mSearch from './module/search.vue'
import mOperate from './module/operate.vue'

import app from './public/app.vue'
import tabListM from './public/tab_list_m.vue'
import scrollListM from './public/scroll_list_m.vue'
import scrollItemM from './public/scroll_item_m.vue'
import buttonListM from './public/button_list_m.vue'
import detailCardM from './public/detail_card_m.vue'

// 导出组件
export default {
	install: function(Vue) {
		Vue.component('page', page);
		Vue.component('moduleGroup', moduleGroup);
		Vue.component('module', module);
		Vue.component('moduleTab', moduleTab);
		Vue.component('moduleColumn', moduleColumn);
		
		Vue.component('cTitle', cTitle);
		Vue.component('cHint', cHint);
		Vue.component('cInput', cInput);
		Vue.component('cButton', cButton);
		Vue.component('cRadio', cRadio);
		Vue.component('cRadioGroup', cRadioGroup);
		Vue.component('cCheckbox', cCheckbox);
		Vue.component('cCheckboxGroup', cCheckboxGroup);
		Vue.component('cSelect', cSelect);
		Vue.component('cDatetime', cDatetime);
		Vue.component('cColor', cColor);
		Vue.component('cLink', cLink);
		Vue.component('cMedia', cMedia);
		Vue.component('cFile', cFile);
		Vue.component('cEditor', cEditor);
		Vue.component('cSwitch', cSwitch);
		Vue.component('cUploadMedia', cUploadMedia);
		Vue.component('cUploadFile', cUploadFile);
		
		Vue.component('cTable', cTable);
		Vue.component('cTableColumn', cTableColumn);
		Vue.component('cTableButton', cTableButton);
		
		Vue.component('cForm', cForm);
		Vue.component('cFormColumn', cFormColumn);
		Vue.component('cFormItem', cFormItem);
		
		Vue.component('cDetail', cDetail);
		Vue.component('cDetailItem', cDetailItem);
		
		Vue.component('cDialog', cDialog);
		
		Vue.component('mSearch', mSearch);
		Vue.component('mOperate', mOperate);
		
		Vue.component('app', app);
		Vue.component('tabListM', tabListM);
		Vue.component('scrollListM', scrollListM);
		Vue.component('scrollItemM', scrollItemM);
		Vue.component('buttonListM', buttonListM);
		Vue.component('detailCardM', detailCardM);
    }
}