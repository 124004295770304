<template>
	<div>
		<slot :list="dataList"></slot>
	</div>
</template>

<script>
	export default {
		props: {
			value: [Array],
			
			disabled: Boolean
		},
		
		data() {
			return {
				drawId: 1,
				dataList: []
			}
		},
		
		watch: {
			value() {
				this.update();
			},
			
			disabled(val) {
				this.$children.forEach(item => item.disabled = val);
			}
		},
		
		mounted() {
			this.update();
		},
		
		methods: {
			update() {
				this.dataList = Array.from(this.value, item => item);
			},
			
			sortHandle(index, target) {
				var item = this.dataList.splice(index, 1)[0];
				this.dataList.splice(target, 0, item);
			},
			
			sortEnd() {
				this.$emit('input', this.dataList);
			}
		}
	}
</script>
