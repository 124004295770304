<template>
    <div class="g-module-group flex" :class="{'flex-grow-auto': !height}" :style="{'height': parsePixel(height)}">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'moduleGroup',
		
		props: {
			height: [Number, String]
		},
		
		methods: {
			resize() {
				this.$children.forEach(item => {
					if (item.$options.name == 'module') {
						item.resize();
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.g-module + .g-module-group,
	.g-module-group + .g-module-group {
		margin-top: 10px;
	}
	.g-module-group .g-module {
		width: 0;
		margin-top: 0 !important;
		
		&:nth-child(n+2) {
			margin-left: 5px;
		}
		
		&:nth-last-child(n+2) {
			margin-right: 5px;
		}
	}
</style>
