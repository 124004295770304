<template>
	<div class="c-form">
		<c-title v-if="title">{{title}}</c-title>
		<div class="c-form-content">
			<slot></slot>
		</div>
		<div v-if="$slots.button" class="flex-center c-form-button">
			<slot name="button"></slot>
		</div>
	</div>
</template>

<script>
	import {createStore} from './store/index.js'
	import validate from '@/assets/script/validate.js'

	export default {
		name: 'cForm',
		
		props: {
			title: String,
			
			inline: Boolean,  // 表单内容行内排列
			
			itemWidth: {  // 表单项宽度，行内排列生效
				type: [Number, String],
				default: '100%'
			},
			
			unitWidth: [Number, String],  // 表单项控件宽度，行内排列生效
			
			labelAlign: {  // 表单项名称是否对齐
				type: Boolean,
				default: true
			}
		},

		data() {
			return {
				store: this.$parent.$options.name == 'cForm' ? this.$parent.store : createStore(),
				search: false
			}
		},
		
		created() {
			this.search = this.$parent.$options.name == 'mSearch';
			this.store.commit('searchForm', this.search);
			this.store.commit('defaultItemWidth', this.search ? 'auto' : this.itemWidth);
			this.store.commit('defaultUnitWidth', this.search ? '230' : (this.unitWidth || '100%'));
			this.store.commit('labelAlign', this.labelAlign);
		},

		methods: {
			clear() {
				!function poll($children) {
					$children.forEach(item => {
						if (item.$options.name == 'cForm') {
							poll(item.$children);
						} else if (item.$options.name == 'cFormItem') {
							item.clear();
						}
					});
				}(this.$children);
			},
			
			set(data) {
				this.$nextTick(() => {
					!function poll($children) {
						$children.forEach(item => {
							if (item.$options.name == 'cForm') {
								poll(item.$children);
							} if (item.$options.name == 'cFormItem') {
								if (item.type == 'datetime' && item.dateTimeRange) {
									let arr = [];
									item.startName && (arr[0] = data[item.startName]);
									item.endName && (arr[1] = data[item.endName]);
									item.setData(arr[0] && arr[1] ? arr : []);
								} else if (item.type == 'region' && data[item.provinceName] && data[item.cityName] && data[item.countyName]) {
									item.setData({
										province: data[item.provinceName],
										city: data[item.cityName],
										county: data[item.countyName],
										address: data[item.addressName]
									});
								} else if (item.name && data[item.name] !== undefined) {
									item.setData(data[item.name]);
								}
							}
						});
					}(this.$children);
				});
			},

			serialize() {
				var data = {};
					
				!function poll($children) {
					$children.forEach(item => {
						if (item.$options.name == 'cForm') {
							poll(item.$children);
						} else if (item.$options.name == 'cFormItem') {
							if (item.type == 'datetime' && item.dateTimeRange) {
								item.startName && (data[item.startName] = item.getData()[0]);
								item.endName && (data[item.endName] = item.getData()[1]);
							} else if (item.type == 'region') {
								['province', 'city', 'county', 'address'].forEach(field => {
									item[`${field}Name`] && (data[item[`${field}Name`]] = item.getData()[field]);
								});
							} else if (item.name) {
								data[item.name] = item.getData();
							}
						}
					});
				}(this.$children);
				
				return data;
			},
			
			submit(opt) {
				opt.SYS_AJAX = (opt) => {
					this.request(opt);
				}
				
				var callback = opt.success;
				opt.success = data => {
					if (!opt.local && !opt.url) return;
					if (opt.dataFormatter) {
						data = opt.dataFormatter(data);
					}
					
					opt.local ? callback(data) : this.request({
						url: opt.url,
						data: data,
						loading: opt.loadingText,
						success: (res, msg) => {
							callback(data, res, msg);
						}
					});
				};
				validate(this.serialize(), opt);
			}
		}
	}
</script>

<style lang="stylus">
	@import './style/index'
</style>
