<template>
    <div class="g-body flex-column" @click="$emit('click')">
		<slot></slot>
	</div>
</template>

<script>
	import global from '@/assets/script/function.js'
	
	export default {
		mounted() {
			this.resize();
			window.addEventListener('resize', this.resize);
		},
		
		activated() {
			this.resize();
			window.addEventListener('resize', this.resize);
		},
		
		deactivated() {
			window.removeEventListener('resize', this.resize);
		},
		
		beforeDestroy() {
			window.removeEventListener('resize', this.resize);
		},
		
		methods: {
			resize: global.debounce(function () {
				this.$children.forEach(item => {
					if (['moduleGroup', 'module'].includes(item.$options.name)) {
						item.resize();
					}
				});
			}, 500, false)
		}
	};
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/mobile.styl'
	
	.g-body {
		box-sizing: border-box;
		min-height: 100%;
		padding: 10px 10px 0;
		
		mobile({
			padding: 0
		})
	}
</style>
