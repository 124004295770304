<template>
	<div>
		<div ref="div" :id="id"></div>
	</div>
</template>

<script>
	import '@/assets/script/lib/kindeditor/kindeditor-all.js'
	import '@/assets/script/lib/kindeditor/themes/default/default.css'

	export default {
		props: {
			width: {
				type: [String, Number],
				default: '100%'
			},
			
			height: {
				type: [String, Number],
				default: 300
			},
			
			content: String
		},

		data() {
			return {
				id: `KindEditor${KindEditorIndex++}`
			};
		},
		
		watch: {
			content(val) {
				this.editor.html(val);
			}
		},
		
		mounted() {
			setTimeout(() => {
				if (!this.editor) {
					this.create();
				}
				this.editor.html(this.content);
			});
		},
		
		activated() {
			this.remove();
			this.create();
		},
		
		beforeDestroy() {
			this.remove();
		},

		methods: {
			create() {
				this.editor = KindEditor.create('#'+this.id, {
					width: this.width,
					height: this.height
				});
			},
			
			remove() {
				this.editor && this.editor.remove();
			},
			
			getContent() {
				return this.editor.html();
			}
		}
	};
</script>