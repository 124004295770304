<template>
	<div class="c-input" :class="{'z-dis': disabled, 'z-focus': focusState}" :style="{'width': parsePixel(width), 'height': parsePixel(height), 'padding-right': word ? 0 : '8px'}">
		<div class="flex-center">
			<input v-model="valueData" class="flex-grow" :placeholder="placeholder" :maxlength="maxlength" :disabled="disabled" :readonly="readonly"
				@focus="inputHandle"
				@input="inputHandle"
				@blur="inputHandle"
				@click="$emit('click')"
				@change="$emit('change')"
			>
			<span v-if="word" class="flex-center">{{word}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			type: {
				type: String,
				default: 'text'
			},
			
			value: {
                type: [Number, String],
                default: ''
            },
			
			width: [Number, String],
			
			height: {
				type: [Number, String],
				default: 30
			},
			
			placeholder: String,
			
			maxlength: [Number, String],
			
			decimal: [Number, String],
			
			limit: [Number, String, Array],
			
			disabled: Boolean,
			
			readonly: Boolean,
			
			word: String
		},
		
		data() {
			return {
				valueData: this.value === undefined ? '' : this.value,
				focusState: false,
			}
		},
		
		computed: {
			minLimit() {
				if (this.limit !== undefined && typeof this.limit == 'object') {
					return parseFloat(this.limit[0]);
				}
				return '';
			},
			
			maxLimit() {
				if (this.limit !== undefined) {
					return typeof this.limit == 'object' ? parseFloat(this.limit[1]) : parseFloat(this.limit);
				}
				return '';
			}
		},
		
		watch: {
			value(val) {
				if (this.valueData != val) {
					this.valueData = val;
					this.inputHandle({
						type: 'blur'
					});
				}
			},
			
			valueData(val) {
				this.$emit('input', val);
			}
		},
		
		methods: {
			inputHandle(e) {
				if (this.type == 'number') {
					// var reg = /\d+/g;
					var decimal = this.decimal || 0;
	
					var reg = [
						[decimal ? '[^-\\d\\.]+$' : '[^-\\d]+$', ''],  //过滤非数字、负号、点的输入
						['^(-?)0?(\\d+)$', '$1$2'],					   //过滤首位为0的输入
						['^(-?)\\.$', '$1'],						   //过滤首位为.的输入
						['\\.(\\d*)\\.+', '.$1'],					   //过滤第二个.
						['^(\\d+\\.?\\d*)-+', '$1'],				   //过滤除第一位之外的-
						['^(-\\d*\\.?\\d*)-+', '$1'],				   //过滤除第一位之外的-
						['^(-?\\d+\\.\\d{'+decimal+'}).+', '$1']	   //过滤多余小数位
					];
					
					reg.forEach(item => {
						this.valueData = this.valueData.toString().replace(new RegExp(item[0]), item[1]);
					});
					
					if (this.valueData !== '' && e.type != 'input') {
						if (this.minLimit !== '' && this.valueData < this.minLimit) {
							this.valueData = this.minLimit.toString();
						}
						if (this.maxLimit !== '' && this.valueData > this.maxLimit) {
							this.valueData = this.maxLimit.toString();
						}
					}
				}
				
				if (e.type != 'input') {
					this.$emit(e.type, e);
				}
				
				if (e.type == 'focus') {
					this.focusState = true;
				} else if (e.type == 'blur') {
					this.focusState = false;
				}
			}
		}
	};
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/mobile'
	
	.c-input {
		box-sizing: border-box;
		display: inline-block;
		border: 1px solid #ddd;
		padding: 0 8px;
		background: #fff;
		transition: border .2s ease;
				
		mobile({
			font-size: .28rem
		})
				
		&.z-focus {
			border-color: #bbb;
			
			span {
				border-color: #bbb;
			}
		}

		&.z-dis {
			background: #F5F5F5;
			
			input {
				color: #666;
				cursor: not-allowed;
			}
		}
		
		div {
			height: 100%;
		
			input {
				box-sizing: border-box;
				width: 100%;
				height: 100%;
				border: none;
				background: none;
			}
			
			span {
				height: 100%;
				border-left: 1px solid #ddd;
				padding: 0 8px;
				margin-left: 8px;
				font-size: 14px;
				color: #333;
				background: #FAFAFA;
				transition: all .2s ease;
			}
		}
	}
</style>
