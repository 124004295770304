import Vue from 'vue';
import store from '@/store';
import message from './message.vue';
import loading from './loading.vue';
import confirm from './confirm.vue';
import preview from './preview.vue';

const components = [
    message,
    loading,
    confirm,
	preview
];

components.forEach(comp => {
    comp.constructor = Vue.extend(comp);
});

export default function() {
    components.forEach(comp => {
        Vue.prototype[`$${comp.name}`] = function(opt = {}) {
            const vm = new comp.constructor({
				store,
                el: document.createElement('div'),
                data() {
                    return opt
                }
            });
    
            (opt.dom || document.body).appendChild(vm.$el);

            return vm;
        }
    });

    Vue.prototype.$loadOver = function() {
        window.$loadingObj && window.$loadingObj.close();
        delete window.$loadingObj;
    }
}