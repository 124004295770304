<template>
	<div class="c-checkbox" :class="{'z-chk': checkState, 'z-dis': disabled}" @click="checkHandle(!checkState, true)">
		<div class="flex-center"><i v-show="checkState" class="tf tf-check"></i></div>
		<span v-if="$slots.default"><slot></slot></span>
	</div>
</template>

<script>
	export default {
		name: 'cCheckbox',
		
		props: {
			checked: Boolean,
			
			value: [Number, String],
			
			disabled: Boolean
		},

		data() {
			return {
				checkState: !!this.checked
			};
		},

		watch: {
			checked(val) {
				this.checkState = !!val;
			},
			
			checkState(val) {
				this.$emit('change', val);
				
				if (this.$parent.$options.name == 'cCheckboxGroup') {
					this.$parent.checkHandle();
				}
			}
		},

		methods: {
			checkHandle(state, click) {
				if (this.disabled && click) return;
				this.checkState = state;
				this.$emit('click', state);
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.c-checkbox {
		display: inline-block;
		height: 20px;
		overflow: hidden;
		cursor: pointer;
		
		&:hover {
			div {
				border-color: #bbb;
			}
		}

		&.z-chk {
			div {
				border-color: #1492FF;
				background: #1492FF;
			}
			
			span {
				font-weight: bold;
			}
		}
		
		&.z-dis {
			cursor: not-allowed;
			
			div {
				border-color: #ddd;
				background: #ddd;
			}
		}

		div {
			float: left;
			position: relative;
			width: 18px;
			height: 18px;
			border: 1px solid #ddd;
			font-size: 12px;
			color: #fff;
			background: #fff;
			transition: all .1s ease;
		}
		
		span {
			float: left;
			margin-left: 5px;
			line-height: 18px;
			font-size: 14px;
		}
	}
</style>
