<template>
	<div class="c-datetime">
		<input v-if="mobile" v-model="valueData" class="c-datetime-input" id="demo" type="text" readonly>
		<el-date-picker v-else v-model="valueData" :type="compType" :placeholder="placeholder" :value-format="format"></el-date-picker>
	</div>
</template>

<script>
	import datePicker from '@/assets/script/lib/datePicker'
	
	export default {
		props: {
			value: [String, Array],
			
			type: {
				type: String,
				default: 'datetime'
			},
			
			range: Boolean,
			
			placeholder: String
		},

		data() {
			return {
				mobile: this.$store.state.mobile,
				valueData: this.value
			}
		},
		
		computed: {
			compType() {
				return `${this.type}${this.range ? 'range' : ''}`;
			},
			
			format() {
				switch (this.type) {
				case 'datetime':
					return 'yyyy-MM-dd HH:mm:ss';
					
				case 'date':
					return 'yyyy-MM-dd';
					
				case 'time':
					return 'HH:mm:ss';
				}
				
				return '';
			}
		},
		
		watch: {
			value(val) {
				this.valueData = val;
			},
			
			valueData(val) {
				this.$emit('input', val);
			}
		},
		
		mounted() {
			if (this.mobile) {
				var calendar = new datePicker();
				calendar.init({
					trigger: "#demo",
					type: this.type,
					onSubmit: function() {
					},
					onClose: function() {
					}
				})
			}
		}
	}
</script>

<style lang="stylus">
	.date_ctrl {
		background: #fff;
		
		.date_btn_box {
			background: #fff;
			
			&:after {
				display: none;
			}
		}
		
		.date_roll_mask {
			padding: .4rem 0;
		}
		
		.datetime_roll {
			font-weight: normal;
		}
	}
</style>

<style lang="stylus" scoped>
	.c-datetime {
		>>> .el-date-editor {
			display: block;
			width: auto;
			
			.el-input__inner {
				height: 38px;
				border-radius: 0;
				font-size: 14px;
				transition: all .3s ease;
				
				&:focus {
					border-color: #bbb;
				}
			}
			
			.el-input__icon {
				line-height: 38px;
			}
		}
		
		>>> .el-range-editor {
			height: 38px;
			border-radius: 0;
			font-size: 0;
			transition: all .3s ease;
				
			&.is-active {
				border-color: #bbb;
			}
				
			&.el-input__inner {
				padding: 0 5px 0 10px;
			}
			
			.el-range-input {
				height: 36px;
				font-size: 14px;
				text-align: left;
			}
			
			.el-range-separator {
				height: 36px;
				line-height: 36px;
			}
			
			.el-input__icon {
				line-height: 36px;
			}
		}
		
		&-input {
			box-sizing: border-box;
			width: 100%;
			height: .8rem;
			border: 1px solid #ddd;
			padding: 0 .2rem;
			font-size: .28rem;
		}
	}
</style>