const menuList = [{
	name: 'project_approve',
	path: 'project_approve',
	meta: {
		title: '待审批项目',
		menu: 'project'
	},
	component: () => import('@/pages/project/project_approve_pc')
}, {
	name: 'project_list',
	path: 'project_list',
	meta: {
		title: '项目列表',
		menu: 'project'
	},
	component: () => import('@/pages/project/project_list'),
	props: route => route.query
}, {
	name: 'person_change',
	path: 'person_change',
	meta: {
		title: '人员变动审批',
		menu: 'project'
	},
	component: () => import('@/pages/project/person_change_pc')
}, {
	name: 'contract_create',
	path: 'contract_create',
	meta: {
		title: '新建合同',
		menu: 'contract'
	},
	component: () => import('@/pages/contract/contract_create'),
	props: route => route.query
}, {
	name: 'contract_approve',
	path: 'contract_approve',
	meta: {
		title: '待审批合同',
		menu: 'contract'
	},
	component: () => import('@/pages/contract/contract_approve_pc')
}, {
	name: 'contract_list',
	path: 'contract_list',
	meta: {
		title: '合同文件管理',
		menu: 'contract'
	},
	component: () => import('@/pages/contract/contract_list')
}, {
	name: 'proceed_approve',
	path: 'proceed_approve',
	meta: {
		title: '待审批开工令',
		menu: 'proceed'
	},
	component: () => import('@/pages/proceed/proceed_approve_pc')
}, {
	name: 'proceed_list',
	path: 'proceed_list',
	meta: {
		title: '开工令列表',
		menu: 'proceed'
	},
	component: () => import('@/pages/proceed/proceed_list')
}, {
	name: 'insurance_list',
	path: 'insurance_list',
	meta: {
		title: '保险单列表',
		menu: 'insurance'
	},
	component: () => import('@/pages/insurance/insurance_list')
}, {
	name: 'ledger_approve',
	path: 'ledger_approve',
	meta: {
		title: '待审批安全台账',
		menu: 'ledger'
	},
	component: () => import('@/pages/ledger/ledger_approve_pc')
}, {
	name: 'ledger_list',
	path: 'ledger_list',
	meta: {
		title: '安全台账列表',
		menu: 'ledger'
	},
	component: () => import('@/pages/ledger/ledger_list')
}, {
	name: 'claim_approve',
	path: 'claim_approve',
	meta: {
		title: '待确认理赔',
		menu: 'claim'
	},
	component: () => import('@/pages/claim/claim_approve_pc')
}, {
	name: 'claim_list',
	path: 'claim_list',
	meta: {
		title: '理赔列表',
		menu: 'claim'
	},
	component: () => import('@/pages/claim/claim_list')
}, {
	name: 'patrol',
	path: 'patrol',
	meta: {
		title: '巡更系统',
		menu: 'patrol'
	},
	component: () => import('@/pages/empty')
}, {
	name: 'statistic',
	path: 'statistic',
	meta: {
		title: '数据统计',
		menu: 'statistic'
	},
	component: () => import('@/pages/statistic/index')
}, {
	name: 'organize_list',
	path: 'organize_list',
	meta: {
		title: '组织架构',
		menu: 'system',
		administrator: true
	},
	component: () => import('@/pages/system/organize_list_pc')
}, {
	name: 'account_list',
	path: 'account_list',
	meta: {
		title: '子账号管理',
		menu: 'system',
		administrator: true
	},
	component: () => import('@/pages/system/account_list_pc')
}]

const ROUTES = [{
	path: '/login',
	meta: {
		title: '登录'
	},
	component: () => import('@/pages/login/index')
}, {
	path: '/register',
	meta: {
		title: '业主单位登记'
	},
	component: () => import('@/pages/register/index')
}, {
	path: '/',
	component: () => import('@/pages/index'),
	children: [{
		path: '/',
		name: 'home',
		meta: {
			title: '工作台'
		},
		component: () => import('@/pages/home/index')
	}, {
		path: 'transact',
		name: 'transact',
		meta: {
			title: '业务处理'
		},
		component: () => import('@/pages/home/transact')
	}, {
		path: 'mine',
		name: 'mine',
		meta: {
			title: '我的'
		},
		component: () => import('@/pages/home/mine')
	}, {
		path: 'manual',
		name: 'manual',
		meta: {
			title: '操作手册'
		},
		component: () => import('@/pages/manual/index')
	}].concat(menuList).concat([{
		name: 'project_detail',
		path: 'project_detail',
		meta: {
			title: '项目详情'
		},
		component: () => import('@/pages/project/project_detail'),
		props: route => route.query
	}, {
		path: 'project_edit',
		name: 'project_edit',
		meta: {
			title: '项目编辑'
		},
		component: () => import('@/pages/project/project_edit_m'),
		props: route => route.query
	}, {
		path: 'project_bidding',
		name: 'project_bidding',
		meta: {
			title: '设置中标企业'
		},
		component: () => import('@/pages/project/project_bidding_m'),
		props: route => route.query
	}, {
		path: 'person_change_detail',
		name: 'person_change_detail',
		meta: {
			title: '人员变更详情'
		},
		component: () => import('@/pages/project/person_change_detail'),
		props: route => route.query
	}, {
		name: 'contract_edit',
		path: 'contract_edit',
		meta: {
			title: '编辑合同',
		},
		component: () => import('@/pages/contract/contract_edit'),
		props: route => route.query
	}, {
		name: 'contract_detail',
		path: 'contract_detail',
		meta: {
			title: '合同详情',
		},
		component: () => import('@/pages/contract/contract_detail'),
		props: route => route.query
	}, {
		name: 'proceed_detail',
		path: 'proceed_detail',
		meta: {
			title: '开工令详情',
		},
		component: () => import('@/pages/proceed/proceed_detail'),
		props: route => route.query
	}, {
		name: 'insurance_detail',
		path: 'insurance_detail',
		meta: {
			title: '保险单详情',
		},
		component: () => import('@/pages/insurance/insurance_detail'),
		props: route => route.query
	}, {
		path: 'claim_apply',
		name: 'claim_apply',
		meta: {
			title: '申请理赔'
		},
		component: () => import('@/pages/insurance/claim_apply_m'),
		props: route => route.query
	}, {
		name: 'ledger_detail',
		path: 'ledger_detail',
		meta: {
			title: '安全台账详情',
		},
		component: () => import('@/pages/ledger/ledger_detail'),
		props: route => route.query
	}, {
		name: 'claim_detail',
		path: 'claim_detail',
		meta: {
			title: '理赔详情',
		},
		component: () => import('@/pages/claim/claim_detail'),
		props: route => route.query
	}, {
		name: 'message_list',
		path: 'message_list',
		meta: {
			title: '消息中心'
		},
		component: () => import('@/pages/message/message_list')
	}, {
		name: 'system_list',
		path: 'system_list',
		meta: {
			title: '系统管理',
			menu: 'system'
		},
		component: () => import('@/pages/system/system_list_m'),
		props: route => route.query
	}, {
		name: 'organize_edit',
		path: 'organize_edit',
		meta: {
			title: '编辑组织架构',
		},
		component: () => import('@/pages/system/organize_edit_m'),
		props: route => route.query
	}, {
		name: 'account_edit',
		path: 'account_edit',
		meta: {
			title: '编辑子账号',
		},
		component: () => import('@/pages/system/account_edit_m'),
		props: route => route.query
	}])
}, {
	path: '*',
	redirect: '/'
}]

let pageId = 1;
!function loop(list) {
	list.forEach(item => {
		if (item.meta) {
			item.meta.id = pageId++;
		}
		if (item.children) {
			loop(item.children);
		}
	});
}(ROUTES);

export default ROUTES

export {menuList}

export let menuGroup = {
	project: {
		title: '项目管理',
		icon: 'tf-menu-project',
		color: '#3296FA',
		path: 'project_list'
	},
	
	insurance: {
		title: '保险单管理',
		icon: 'tf-menu-insurance',
		color: '#00B853',
		path: 'insurance_list'
	},
	
	proceed: {
		title: '开工令管理',
		icon: 'tf-menu-proceed',
		color: '#F55545',
		path: 'proceed_list'
	},
	
	contract: {
		title: '合同管理',
		icon: 'tf-menu-contract',
		color: '#FF9F00',
		path: 'contract_list'
	},
	
	ledger: {
		title: '安全台账管理',
		mobileTitle: '安全台账',
		icon: 'tf-menu-ledger',
		color: '#00B853',
		path: 'ledger_list'
	},
	
	claim: {
		title: '理赔管理',
		icon: 'tf-menu-claim',
		color: '#FF9F00',
		path: 'claim_list'
	},
	
	patrol: {
		title: '巡更系统',
		icon: 'tf-menu-patrol',
		color: '#F55545',
		path: 'patrol'
	},
	
	statistic: {
		title: '数据统计',
		icon: 'tf-menu-statistic',
		color: '#3296FA',
		path: 'statistic'
	},
	
	system: {
		title: '系统管理',
		icon: 'tf-menu-system',
		color: '#3296FA',
		path: 'system_list'
	}
}