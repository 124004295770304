<template>
	<div class="c-checkbox-group">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'cCheckboxGroup',
		
		props: {
			value: [String, Number, Array],
		},

		data() {
			return {
				valueData: this.value
			};
		},

		watch: {
			value(val) {
				this.valueData = val;
			},
			
			valueData() {
				this.update();
			}
		},
		
		mounted() {
			this.update();
		},

		methods: {
			update() {
				var value = this.valueData ? (typeof this.valueData == 'object' ? this.valueData : this.valueData.toString().split(',')) : [];
				this.$children.forEach(child => {
					if (child.$options.name == 'cCheckbox') {
						child.checkHandle(value.findIndex(item => item == child.value) > -1);
					}
				});
			},
			
			checkHandle() {
				this.valueData = Array.from(this.$children.filter(item => {
					return item.$options.name == 'cCheckbox' && item.checkState;
				}), item => {
					return item.value;
				});
				this.$emit('input', this.valueData);
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.c-checkbox-group {
		.c-checkbox {
			margin-right: 10px;
			
			&:nth-last-child(1) {
				margin-right: 0;
			}
		}
	}
</style>
