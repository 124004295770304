<template>
    <div class="c-link flex" @click="selectLinkHandle">
		<div class="flex-grow flex-center-cross">
			<div v-if="compValue" class="tag ellipsis" @click.stop>
				<span class="flex-grow">{{compValue}}</span>
				<a class="tf tf-cross" @click="clear"></a>
			</div>
		</div>
		<div class="icon flex-center"><i class="tf tf-link"></i></div>
	</div>
</template>

<script>
	export default {
		props: {
			value: [Number, String]
		},
		
		computed: {
			compValue() {
				if (this.value && this.value.includes('#link#')) {
					var data = this.value.split('#link#'),
						type = {
							'7': '商品列表',
							'8': '商品'
						};
					
					return `[${type[data[0]]}] ${data[2] || data[1]}`;
				}
				
				return '';
			}
		},
		
		methods: {
			selectLinkHandle() {
				console.log(this.value);
				this.selectLink(this.value, {
					resolve: link => {
						this.$emit('input', link);
					}
				});
			},
			
			clear() {
				this.$emit('input', '');
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.c-link {
		height: 36px;
		border: 1px solid #ddd;
		background: #fff;
		cursor: pointer;
		
		.icon {
			width: 36px;
			height: 36px;
			border-left: 1px solid #ddd;
			font-size: 18px;
			color: #666;
		}
		
		.tag {
			display: inline-block;
			position: relative;
			max-width: 100%;
			height: 26px;
			padding: 0 25px 0 5px;
			margin: 5px;
			line-height: 26px;
			font-size: 12px;
			background: #eee;
			cursor: auto;
			
			a {
				position: absolute;
				right: 0;
				top: 0;
				width: 20px;
				margin-left: 5px;
				font-size: 12px;
				text-align: center;
				color: #fff;
				background: #FF1010;
			}
		}
	}
</style>
