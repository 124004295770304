<template>
	<c-dialog ref="dialog" type="confirm" :title="false" :width="width" fadein :close-button="false">
		<div class="c-confirm flex-column flex-center-main">
			<div class="flex-center-cross">
				<i v-if="iconClass" class="tf" :class="[iconClass]"></i>
				<h5 v-html="text"></h5>
			</div>
			<p v-html="message"></p>
		</div>
			
		<template slot="button">
			<c-button @click="buttonHandle('resolve')">{{buttonName}}</c-button>
			<c-button v-if="cancelButton" color="gray" hollow @click="buttonHandle('cancel')">取消</c-button>
		</template>
	</c-dialog>
</template>

<script>
	export default {
		name: 'confirm',

		data() {
			return {
				title: '消息提醒',
				width: 500,
				text: '',
				message: '',
				icon: 'warn',
				buttonName: '确定',
				cancelButton: true
			};
		},

		mounted() {
			this.$refs.dialog.open();
		},

		computed: {
			iconClass() {
				return {
					warn: 'tf-confirm-warn',
					message: 'tf-confirm-message'
				}[this.icon];
			}
		},

		methods: {
			buttonHandle(state) {
				this[state] && this[state]();
				this.$refs.dialog.close();
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.c-confirm {
		min-height: 100px;
		padding: 32px 55px 0 32px;
		word-break: break-all;
		
		.tf {
			width: 40px;
			font-size: 24px;
		}
		
		h5 {
			line-height: 32px;
			font-size: 16px;
			color: #000;
		}
		
		p {
			margin-top: 12px;
			padding-left: 40px;
			line-height: 24px;
			font-size: 14px;
			color: #666;
		}
	}
</style>
