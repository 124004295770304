<template>
	<div class="c-radio" :class="{'z-chk': checkState}" @click="checkHandle(true)">
		<div></div>
		<span v-if="$slots.default"><slot></slot></span>
	</div>
</template>

<script>
	export default {
		name: 'cRadio',
		
		props: {
			checked: Boolean,
			
			value: [Number, String]
		},

		data() {
			return {
				checkState: !!this.checked
			};
		},

		watch: {
			checked(val) {
				this.checkState = !!val;
			},
			
			checkState(val) {
				this.$emit('change', val);
				
				if (val && this.$parent.$options.name == 'cRadioGroup') {
					this.$parent.checkHandle(this.value);
				}
			}
		},

		methods: {
			checkHandle(state) {
				this.checkState = state;
				this.$emit('click', state);
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.c-radio {
		display: inline-block;
		height: 20px;
		font-size: 13px;
		overflow: hidden;
		cursor: pointer;
		
		&:hover {
			div {
				border-color: #bbb;
			}
		}

		&.z-chk {
			div {
				border-color: #000;
				
				&:after {
					width: 9px;
					height: 9px;
				}
			}
			
			span {
				font-weight: bold;
			}
		}

		div {
			float: left;
			position: relative;
			width: 18px;
			height: 18px;
			border: 1px solid #ddd;
			border-radius: 100%;
			background: #fff;
			transition: all .1s ease;
			
			&:after {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				width: 0;
				height: 0;
				border-radius: 100%;
				background: #000;
				transform: translate(-50%,-50%);
				transition: all .1s ease;
			}
		}
		
		span {
			float: left;
			margin-left: 5px;
			line-height: 20px;
		}
	}
</style>
