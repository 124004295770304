<template>
	<div style="display: none;"><slot></slot></div>
</template>

<script>
	export default {
		props: {
			power: String
		},
		
		computed: {
		}
	};
</script>