<template>
	<div class="c-color">
		<el-color-picker v-model="valueData" :clearable="false"></el-color-picker>
	</div>
</template>

<script>
	export default {
		props: {
			value: String
		},

		data() {
			return {
				valueData: this.value
			}
		},

		watch: {
			value(val) {
				this.valueData = val;
			},
			
			valueData(val) {
				this.$emit('input', val || '');
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.c-color {
		>>> .el-color-picker__trigger {
			width: 38px !important;
			height: 38px !important;
			border-color: #ddd;
			transition: all .3s ease;
			
			&:hover {
				border-color: #bbb;
			}
		}
	}
</style>
