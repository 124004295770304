import Vue from 'vue';

const SHORTCUTS_CONFIG = {};
const SHORTCUTS_EVENT = {
    'ctrl+u': function() {
        window.$app.navigateTo('/cashier');
    },
    'ctrl+i': function() {
        window.$app.navigateTo('/orderList');
    },
    'ctrl+o': function() {
        window.$app.navigateTo('/afterSale');
    },
    'ctrl+p': function() {
        window.$app.navigateTo('/handOver');
    },
    'ctrl+g': function() {
        window.$app.navigateTo('/service');
    },
    'ctrl+e': function() {
        window.$app.navigateTo('/orderList/filter/1');
    }
};

(function() {
    let inputFocus = false;

    let dictionary = {
        '~': '`',
        '!': '1',
        '@': '2',
        '#': '3',
        '$': '4',
        '%': '5',
        '^': '6',
        '&': '7',
        '*': '8',
        '(': '9',
        ')': '0',
        '_': '-',
        '+': '=',
        '{': '[',
        '}': ']',
        ':': ';',
        '"': "'",
        '<': ',',
        '>': '.',
        '?': '/',
        ' ': 'space',
        'arrowup': 'up',
        'arrowdown': 'down',
        'arrowleft': 'left',
        'arrowright': 'right'
    }

    document.addEventListener('keydown', function(e) {
        if (!e.key) return;
        switch(e.key) {
        case 'Shift':
            SHORTCUTS.shift = true;
            break;
        case 'Control':
            SHORTCUTS.ctrl = true;
            break;
        case 'Alt':
            SHORTCUTS.alt = true;
            break;
        default:
            SHORTCUTS.keymap(e.key.toLowerCase(), e);
        }
    });
    document.addEventListener('keyup', function(e) {
        switch(e.key) {
        case 'Shift':
            SHORTCUTS.shift = false;
            break;
        case 'Control':
            SHORTCUTS.ctrl = false;
            break;
        case 'Alt':
            SHORTCUTS.alt = false;
            break;
        }
    });

    !function poll() {
        inputFocus = (document.activeElement.tagName == 'INPUT' || document.activeElement.tagName == 'TEXTAREA');
        setTimeout(poll, 300);
    }();

    var SHORTCUTS = Vue.prototype.$shortcuts = {
        config: function(config) {
            for (var i in config) {
                SHORTCUTS_CONFIG[i] = config[i];
            }
            return SHORTCUTS;
        },

        listener(key, fun) {
            (typeof key != 'object' ? [key] : key).forEach(function(item) {
                SHORTCUTS_EVENT[item] = fun;
            });
            return SHORTCUTS;
        },

        stop(key) {
            this.listener(key, null);
            return SHORTCUTS;
        },

        keymap: function(key, e) {
            key = (this.ctrl ? 'ctrl+' : '') + (this.shift ? 'shift+' : '') + (this.alt ? 'alt+' : '') + (dictionary[key] || key);

            if (SHORTCUTS_CONFIG.beforeKeydown && SHORTCUTS_CONFIG.beforeKeydown({key: key}) === false) return;

            if (inputFocus && !SHORTCUTS.ctrl && (!SHORTCUTS_CONFIG.beforeInput || SHORTCUTS_CONFIG.beforeInput({key: key}) !== false)) return;

            if (SHORTCUTS_EVENT[key]) {
                e && e.preventDefault();
                SHORTCUTS_EVENT[key]({
                    key: key
                });
            }
            return SHORTCUTS;
        }
    }
})()