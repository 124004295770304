import Vue from 'vue';
import Vuex from 'vuex';
import {menuList, menuGroup} from '@/router/routes.js'
import fun from '@/assets/script/function.js'

Vue.use(Vuex);

const COMMON_APPROVE_STATE = [{
	name: '待监理审批',
	value: 1,
	color: '#1492FF'
}, {
	name: '待业主审批',
	value: 2,
	color: '#1492FF'
}, {
	name: '已完成',
	value: 3,
	color: '#00B575'
}, {
	name: '驳回',
	value: 4,
	color: '#E63633'
}];

function stateSerialize(data) {
	let json = {};
	data.forEach(item => {
		if (typeof item.value == 'object') {
			item.value.forEach(i => {
				json[i] = item;
			});
		} else {
			json[item.value] = item;
		}
	});
	return json;
}

let app = new Vue();
export default new Vuex.Store({
	state: {
		mobile: window.outerWidth <= 750,
		networkError: false,
		userInfo: {},
		messageTotal: 0,
		
		// 行业列表
		hangyeList: [{
			name: '交通',
			value: '交通'
		}, {
			name: '水利',
			value: '水利'
		}, {
			name: '市政',
			value: '市政'
		}, {
			name: '住建',
			value: '住建'
		}],
		
		// 发票类型
		invoiceType: [{
			name: '普通发票',
			value: 1
		}, {
			name: '增值税专用发票',
			value: 2
		}, {
			name: '不要发票',
			value: 3
		}],
		
		// 项目状态
		projectStateList: [{
			name: '驳回',
			value: 1,
			color: '#E63633'
		}, {
			name: '待确认',
			value: 2,
			color: '#1492FF'
		}, {
			name: '待设置中标企业',
			value: 3,
			color: '#1492FF'
		}, {
			name: '待业主领导审批',
			value: 31,
			color: '#1492FF'
		}, {
			name: '待业主签署',
			value: 32,
			color: '#1492FF'
		}, {
			name: '创建成功',
			value: 4,
			color: '#00B575'
		}],
		
		// 合同状态
		contractStateList: [{
			name: '待业主领导审批',
			value: 0,
			color: '#1492FF'
		}, {
			name: '待业主签署',
			value: 1,
			color: '#1492FF'
		}, {
			name: '待企业签署',
			value: 2,
			color: '#1492FF'
		}, {
			name: '签署完成',
			value: 3,
			color: '#00B575'
		}, {
			name: '驳回',
			value: 4,
			color: '#E63633'
		}],
		
		// 保险状态
		insuranceStateList: [{
			name: '未购买',
			value: 0,
			color: '#E63633'
		}, {
			name: '草稿',
			value: 1,
			color: '#1492FF'
		}, {
			name: '待付款',
			value: 2,
			color: '#1492FF'
		}, {
			name: '待审批',
			value: 3,
			color: '#1492FF'
		}, {
			name: '已完成',
			value: 4,
			color: '#00B575'
		}, {
			name: '驳回',
			value: 5,
			color: '#E63633'
		}],
		
		// 人员变更状态
		personStateList: COMMON_APPROVE_STATE,
		
		// 开工令状态
		proceedStateList: COMMON_APPROVE_STATE,
		
		// 台账状态
		ledgerStateList: [{
			name: '待监理审批',
			value: 1,
			color: '#1492FF'
		}, {
			name: '待业主审批',
			value: 2,
			color: '#1492FF'
		}, {
			name: '待业主审批',
			value: 21,
			color: '#1492FF'
		}, {
			name: '已完成',
			value: 3,
			color: '#00B575'
		}, {
			name: '驳回',
			value: 4,
			color: '#E63633'
		}],
		
		// 理赔状态
		claimStateList: [{
			name: '待审批',
			value: 1,
			color: '#1492FF'
		}, {
			name: '受理中',
			value: 2,
			color: '#1492FF'
		}, {
			name: '待确认',
			value: 3,
			color: '#1492FF'
		}, {
			name: '已完成',
			value: 4,
			color: '#00B575'
		}, {
			name: '驳回',
			value: 5,
			color: '#E63633'
		}],
		
		// 协助理赔状态
		assistClaimStateList: [{
			name: '无需确认',
			value: 0,
			color: '#00B575'
		}, {
			name: '待确认',
			value: 1,
			color: '#1492FF'
		}, {
			name: '已确认',
			value: 2,
			color: '#00B575'
		}, {
			name: '不接受',
			value: 3,
			color: '#E63633'
		}]
	},
	
	getters: {
		navList(state) {
			var list = [];
			menuList.forEach(item => {
				if (item.meta.administrator && state.userInfo.yzdw_is_manage != 2) {
					return;
				}
				
				let menu = menuGroup[item.meta.menu];
				
				let group = list.find(item => {
					return item.title == menu.title
				});
				
				if (!group) {
					group = {
						title: menu.title,
						mobileTitle: menu.mobileTitle,
						icon: menu.icon,
						color: menu.color,
						path: menu.path,
						children: []
					}
					
					list.push(group);
				}
				
				group.children.push({
					title: item.meta.title,
					name: item.name,
					path: '/' + item.path
				});
			});
			
			return list;
		},
		
		invoiceTypeName(state) {
			return stateSerialize(state.invoiceType);
		},
		
		projectState(state) {
			return stateSerialize(state.projectStateList);
		},
		
		contractState(state) {
			return stateSerialize(state.contractStateList);
		},
		
		insuranceState(state) {
			return stateSerialize(state.insuranceStateList);
		},
		
		personState(state) {
			return stateSerialize(state.personStateList);
		},
		
		proceedState(state) {
			return stateSerialize(state.proceedStateList);
		},
		
		ledgerState(state) {
			return stateSerialize(state.ledgerStateList);
		},
		
		claimState(state) {
			return stateSerialize(state.claimStateList);
		},
		
		assistClaimState(state) {
			return stateSerialize(state.assistClaimStateList);
		}
	},
	
	mutations: {
		setNetworkError(state, data) {
			state.networkError = data;
		},
		
		// 设置用户信息
		setUserInfo(state, data) {
			state.userInfo = data;
			
			aplus_queue.push({
				action: 'aplus.setMetaInfo',
				arguments: ['_hold', 'BLOCK']
			});
			aplus_queue.push({
				action: 'aplus.setMetaInfo',
				arguments: ['_user_id', data.zzd_userid]
			});
			aplus_queue.push({
				action: 'aplus.setMetaInfo',
				arguments: ['_hold', 'START']
			});
		},
		
		// 设置未读消息数
		setMessageTotal(state, data) {
			state.messageTotal = data;
		}
	},

	actions: {
		updateUserInfo(store, callback) {
			if (!fun.getCookie('session_id')) {
				store.commit('setUserInfo', {});
				return;
			}
			app.request({
				url: '/user/cache',
				ignoreTimeout: true,
				success: data => {
					store.commit('setUserInfo', data);
				},
				fail: () => {
					store.commit('setUserInfo', {});
					return false;
				},
				complete: (code, data) => {
					callback && callback(code, data);
				}
			});
		},
		
		updateMessageTotal(store) {
			if (!store.state.userInfo.id) return;
			app.request({
				url: '/sysnotice/unCount',
				success: data => {
					store.commit('setMessageTotal', data);
					if (!store.state.mobile) {
						setTimeout(() => {
							store.dispatch('updateMessageTotal');
						}, 5000);
					}
				}
			});
		}
	}
});
