<template>
	<div class="tab flex">
		<div v-for="(item, i) in tab" :key="i" class="item flex-grow flex-center" :class="{'z-crt': currentIndex == i}" @click="clickHandle(i)">{{item.name}}</div>
	</div>
</template>

<script>
	export default {
		props: {
			tab: Array,
			current: String
		},
		
		data() {
			return {
				currentIndex: 0
			}
		},
		
		mounted() {
			this.clickHandle(this.current || 0);
		},
		
		methods: {
			clickHandle(i) {
				this.currentIndex = i;
				this.$emit('change', this.tab[i]);
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.tab {
		z-index: 100;
		position: sticky;
		top: 0;
		background: #fff;
		
		.item {
			position: relative;
			line-height: .88rem;
			font-size: .3rem;
			color: #9B9B9B;
			
			&.z-crt {
				color: #1F1F1F;
				
				&:after {
					content: '';
					position: absolute;
					left: 50%;
					bottom: 0;
					width: .64rem;
					height: .04rem;
					margin-left: -.32rem;
					background: #3296FA;
				}
			}
		}
	}
</style>