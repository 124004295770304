<template>
	<div v-if="src" class="c-file flex">
		<i class="tf tf-fw tf-file" :class="`tf-file-${suffix}`"></i>
		<a @click="openFile(src)">{{fileName}}</a>
	</div>
</template>

<script>
	export default {
		props: {
			src: String
		},

		computed: {
			fileName() {
				if (!this.src) return '';
				let path = this.src.split('/');
				return path[path.length - 1];
			},
			
			suffix() {
				if (!this.src) return '';
				var arr = this.src.split('.');
				return arr[arr.length - 1].toLowerCase();
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.c-file {
		padding: 2px 0;
		line-height: 20px;
		word-break: break-all;
			
		.tf {
			margin-right: 6px;
			font-size: 18px;
		}
		
		a {
			font-size: 14px;
			color: #1492FF;
		}
	}
</style>
