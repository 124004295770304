import Vue from 'vue';

export default Vue.extend({
	data() {
		return {
			labelLength: 0,
			labelAlign: true,
			itemWidth: 0,
			unitWidth: 0,
			inline: false
		}
	},
	
	methods: {
		commit(key, data) {
			this[key] = data;
		},
		
		setLabelLength(length) {
			if (this.labelAlign && this.labelLength < length) {
				this.labelLength = length;
			}
		}
	}
});