import CryptoJs from './lib/crypto-js'

const MD5_KEY = 'V52kPpLS6msz7y3NfZUZ9OKFYeoGysMOeMSPdcObi6onvgmgKKEP5gk3s2omhDDOV3T6SYitc5bKUEzZECnEuN61YvObbu2SD7InNlnWeB8j7M195swDhAYjEnLAI71M'
const EN_KEY = 'b2bc2dcff9074d84'  // 加密秘钥
const DE_KEY = '83fe4c23467507cd'  // 解密秘钥

const fun = {
	// 加密数据
	encryption(data) {
		var timestamp = new Date().getTime(),
			ciphertext = (function(datastr) {
				return CryptoJs.AES.encrypt(datastr, CryptoJs.enc.Utf8.parse(EN_KEY), {
					iv: CryptoJs.enc.Utf8.parse(),
					mode: CryptoJs.mode.ECB,
					padding: CryptoJs.pad.Pkcs7
				}).toString();
			})(JSON.stringify(data));
		
		return {
			ciphertext: ciphertext,
			timestamp: timestamp,
			sign: CryptoJs.MD5(ciphertext + timestamp + MD5_KEY).toString()
		};
	},

	// 解密数据
	deciphering(data) {
		return JSON.parse(
			CryptoJs.AES.decrypt(data, CryptoJs.enc.Utf8.parse(DE_KEY), {
				iv: CryptoJs.enc.Utf8.parse(),
				mode: CryptoJs.mode.ECB,
				padding: CryptoJs.pad.Pkcs7
			}).toString(CryptoJs.enc.Utf8)
		);
	},
	
	/**
	 * 函数防抖
	 * @param  {Function} fun       要执行的方法
	 * @param  {Number}   timer     等待时间
	 * @param  {Booleans} immediate 是否立即执行
	 * @return {Function}           设置防抖后方法
	 */
	debounce: function(fun, timer, immediate) {
		var timeout, args, context, timestamp, result;
		timer = timer || 500;

		var later = function() {
			var last = new Date().getTime() - timestamp;

			if (last < timer && last >= 0) {
				timeout = setTimeout(later, timer - last);
			} else {
				timeout = null;
				if (immediate === false) {
					result = fun.apply(context, args);
					if (!timeout) context = args = null;
				}
			}
		};

		return function() {
			context = this;
			args = arguments;
			timestamp = new Date().getTime();
			var callNow = immediate !== false && !timeout;
			if (!timeout) timeout = setTimeout(later, timer);
			if (callNow) {
				result = fun.apply(context, args);
				context = args = null;
			}

			return result;
		};
	},

	/**
	 * 函数节流
	 * @param  {Function} fun      执行的方法
	 * @param  {Number}   timer    等待时间
	 * @param  {Booleans} leading  是否立即执行一次
	 * @param  {Booleabs} trailing 是否执行最后一次延时调用
	 * @return {Function}          设置节流后的方法
	 */
	throttle: function(fun, timer, leading, trailing) {
		timer = timer || 100;
		var context, args, result;
		var timeout = null;
		var previous = 0;
		var later = function() {
			previous = leading === false ? 0 : new Date().getTime();
			timeout = null;
			result = fun.apply(context, args);
			if (!timeout) context = args = null;
		};
		return function() {
			var now = new Date().getTime();
			if (!previous && leading === false) previous = now;
			var remaining = timer - (now - previous);
			context = this;
			args = arguments;
			if (remaining <= 0 || remaining > timer) {
				if (timeout) {
					clearTimeout(timeout);
					timeout = null;
				}
				previous = now;
				result = fun.apply(context, args);
				if (!timeout) context = args = null;
			} else if (!timeout && trailing !== false) {
				timeout = setTimeout(later, remaining);
			}
			return result;
		};
	},

	setCookie: function(n, v, s) {
		var t = new Date();
		t.setTime(t.getTime() + s * 1000);
		document.cookie = n + "=" + v + ";expires=" + t + ";path=/";
	},

	getCookie: function(name) {
		var arr = document.cookie.replace(/\s/g, "").split(";");
		for (var i = 0; i < arr.length; i++) {
			var tempArr = arr[i].split("=");
			if (tempArr[0] == name) {
				return decodeURIComponent(tempArr[1]);
			}
		}
		return "";
	},

	removeCookie: function(name) {
		fun.setCookie(name, "", -1);
	}
};
export default fun;
