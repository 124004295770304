import Store from './api.js'

export function createStore() {
	return new Store();
};

export function mapState(data) {
	const res = {};
	data.forEach(key => {
		res[key] = function() {
			return this.store[key];
		}
	});
	return res;
};