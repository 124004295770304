import { render, staticRenderFns } from "./radio_group.vue?vue&type=template&id=38550005&scoped=true"
import script from "./radio_group.vue?vue&type=script&lang=js"
export * from "./radio_group.vue?vue&type=script&lang=js"
import style0 from "./radio_group.vue?vue&type=style&index=0&id=38550005&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38550005",
  null
  
)

export default component.exports