import Vue from 'vue';
import buttonGroup from '../button_group.vue'

const SCROLL_SIZE = (function() {
	var noScroll, scroll, oDiv = document.createElement('div');
	oDiv.style.cssText = 'position:absolute; top:-1000px; width:100px; height:100px; overflow:hidden;';
	noScroll = document.body.appendChild(oDiv).clientWidth;
	oDiv.style.overflowY = 'scroll';
	scroll = oDiv.clientWidth;
	document.body.removeChild(oDiv);
	return (noScroll - scroll);
})();

const COLUMN_CONFIG = {
	normal: {
		fixed: false
	},
	
	selector: {
		fixed: 'left',
		width: 37
	},
	
	expand: {
		fixed: false
	},
	
	sort: {
		fixed: false,
		width: 104
	},
	
	button: {
		fixed: 'right'
	}
};

export default Vue.extend({
	components: {
		buttonGroup
	},
	
	data() {
		return {
			columnConfig: [],
			header: [],
			columns: [],
			
			initSelected: '',
			
			dataList: [],
			emptyState: false,
			
			scrollX: false,
			scrollY: false,
			
			rowCLick: false,
			currentRow: ''
		}
	},
	
	computed: {
		columnsWidth() {
			var width = {
				all: 0,
				left: 1,
				right: 1
			}
			this.columns.forEach(col => {
				width.all += col.width;
				if (col.fixed) {
					width[col.fixed] += col.width;
				}
			});
			return width;
		},
		
		scrollXSize() {
			return this.scrollX ? SCROLL_SIZE : 0;
		},
		
		scrollYSize() {
			return this.scrollY ? SCROLL_SIZE : 0;
		},
		
		allSelected() {
			return !!(this.dataList.length && !this.dataList.find(item => !item.selected));
		}
	},
	
	watch: {
		columnConfig(val) {
			var store = this;
			!function poll(cols, level, parent) {
				cols.forEach(col => {
					let root = parent || col;
					let isBottom = !col.$children.find(item => item.$options.name == 'cTableColumn');
					if (isBottom) {
						col.colspan = 1;
						col.rowspan = 1;
						
						let config = {
							fixed: root.powerConfig ? root.powerConfig.fixed : COLUMN_CONFIG[root.type].fixed,
							width: parseInt(COLUMN_CONFIG[col.type].width || col.width),
							renderCell: (row, index) => {
								switch(col.type) {
								case 'normal':
									return col.$scopedSlots.default ? col.$scopedSlots.default(row) : col.$slots.default || row.data[col.name]
									
								case 'button':
									return [
										<button-group limit={parseInt(col.limit)} update-flag={new Date().getTime()}>
											{
												col.$scopedSlots.default(row)
											}
										</button-group>
									]
									
								case 'selector':
									return [
										<div class="flex-center">
											<c-checkbox checked={row.selected} on-change={$event => {store.selectHandle(row, $event, col.multiple)}}></c-checkbox>
										</div>
									]
									
								case 'sort':
									return ['top', 'up', 'down', 'bottom'].map(type => {
										return [
											<a class={`c-table-sort tf tf-sort-${type}`} on-click={$event => store.sortHandle(col, row, type, $event)}></a>
										]
									})
								
								case 'expand':
									var active = (!col.expandLimit || (row.expandData.level < parseInt(col.expandLimit) - 1)) && col.expandButton(row);
									row.expand = () => {
										active && store.expandHandle(col, row, false, true);
									}
									row.fold = () => {
										active && store.expandHandle(col, row, false, false);
									}
									row.load = () => {
										active && store.loadHandle(col, row);
									}
									return [
										<div class="flex-center-cross">
											{
												active ? [
													<a class={`c-table-expand ${row.expandData.state ? 'z-expand': ''}`} style={`margin-left:${row.expandData.level * 25}px;`} on-click={$event => store.expandHandle(col, row, $event)}>
														{
															row.expandData.loading ? [
																<i class="tf tf-loading tf-spin"></i>
															] : [
																<i class="tf tf-angle-right"></i>
															]
														}
													</a>
												] : [
													<span style={`margin-left:${row.expandData.level * 25 + 25}px;`}></span>
												]
											}
											<div class="flex-grow">
												{
													col.$scopedSlots.default ? col.$scopedSlots.default(row) : col.$slots.default || row.data[col.name]
												}
											</div>
										</div>
									]
								}
							}
						};
						
						store.insertColumn(config);
					} else {
						poll(col.$children, level+1, root);
					}
				
					if (col.$parent.$options.name == 'cTableColumn') {
						if (!col.$parent.colspan) col.$parent.colspan = 0;
						col.$parent.colspan += col.colspan;
					}
				
					store.insertHeader({
						level,
						fixed: COLUMN_CONFIG[root.type].fixed,
						colspan: col.colspan,
						label: col.label,
						align: col.align,
						renderHead: () => {
							switch (col.type) {
							case 'selector':
								return col.multiple ? [
									<div class="flex-center">
										<c-checkbox checked={store.allSelected} on-click={$event => {store.selectHandle('all', $event)}}></c-checkbox>
									</div>
								] : ''
								
							default:
								return col.label;
							}
						},
						$vm: col
					});
				});
			}(val, 0);
			
			for (let i=this.header.length-1; i>=0; i--) {
				this.header[i].forEach(col => {
					let floor = this.header.length - i, childrenFloor = 0;
					let $children = col.$vm.$children.filter(item => item.$options.name == 'cTableColumn');
					col.$vm.$children.forEach(item => {
						if (item.$options.name == 'cTableColumn' && childrenFloor < item.rowspan) {
							childrenFloor = item.rowspan;
						}
					});
					col.rowspan = col.$vm.rowspan = floor - childrenFloor;
				});
			}
		}
	},
	
	methods: {
		commit(key, data) {
			this[key] = data;
		},
		
		createRow(opt = {}) {
			var row = Object.assign({
				data: opt.data,
				index: 0,
				expandData: {
					state: false,
					loading: false,
					hidden: opt.expandHidden || false,
					level: opt.expandLevel || 0,
					parent: opt.expandParent,
					children: ''
				},
				selected: this.initSelected ? !!this.initSelected(opt) : false,
				hover: false,
				update: data => {
					for (let key in data) {
						this.$set(row.data, key, data[key]);
					}
					this.table.resize();
				},
				remove: () => {
					(row.expandData.parent ? row.expandData.parent.expandData.children : this.dataList).splice(row.index, 1);
					this.table.resize();
				},
				moveTo: type => {
					let list = row.expandData.parent ? row.expandData.parent.expandData.children : this.dataList;
					
					if (
						((type == 'top' || type == 'up') && row.index == 0) ||
						((type == 'bottom' || type == 'down') && row.index == list.length - 1)
					) return;
					
					list.splice(row.index, 1);
					
					switch(type) {
					case 'top':
						list.unshift(row);
						break;
						
					case 'up':
						list.splice(row.index - 1, 0, row);
						break;
						
					case 'down':
						list.splice(row.index + 1, 0, row);
						break;
						
					case 'bottom':
						list.push(row);
						break;
					}
				},
				prev: () => {
					let list = row.expandData.parent ? row.expandData.parent.expandData.children : this.dataList;
					return list[row.index - 1];
				},
				next: () => {
					let list = row.expandData.parent ? row.expandData.parent.expandData.children : this.dataList;
					return list[row.index + 1];
				},
				expand: () => {}
			}, opt.extend || {});
			return row;
		},
		
		setData(data) {
			this.dataList = Array.from(data, item => {
				return this.createRow({
					data: item
				});
			});
		},
		
		selectHandle(row, state, multiple) {
			if (row == 'all') {
				this.dataList.forEach(item => {
					this.selectHandle(item, state, true);
				})
			} else {
				if (!multiple && state) {
					this.dataList.forEach(item => {
						this.$set(item, 'selected', false);
					});
				}
				this.$set(row, 'selected', state);
			}
		},
		
		expandHandle(col, row, e, state = !row.expandData.state) {
			e && e.stopPropagation();
			if (!state || row.expandData.children) {
				row.expandData.state = state;
				!function poll(row) {
					if (row.expandData.add) {
						row.expandData.add.expandData.hidden = !state
					}
					row.expandData.children && row.expandData.children.forEach(item => {
						item.expandData.hidden = !state;
						
						if (!state) {
							item.expandData.state = state;
							poll(item);
						}
					});
				}(row)
				this.table.resize();
			} else {
				this.loadHandle(col, row, true);
			}
		},
		
		loadHandle(col, row, expand = row.expandData.state) {
			if (row.expandData.loading) return;
			row.expandData.loading = true;
			col.$emit('expand', row, data => {
				row.expandData.state = expand;
				row.expandData.loading = false;
				row.expandData.children = Array.from(data, item => {
					return this.createRow({
						data: item,
						expandLevel: row.expandData.level + 1,
						expandParent: row,
						expandHidden: !expand
					});
				});
				
				let addButton = typeof col.addButton == 'function' ? col.addButton(row) : col.addButton;
				row.expandData.add = addButton ? this.createRow({
					expandLevel: row.expandData.level + 1,
					expandParent: row,
					expandHidden: !expand,
					extend: {
						type: 'expandButton',
						renderCell: row => {
							return [
								<div style={`margin-left:${row.expandData.level * 25 + 25}px;`}>
									<c-button on-click={$event => this.newChildHandle(col, row, $event)}>{typeof addButton == 'string' ? addButton : '添加子分组'}</c-button>
								</div>
							];
						}
					}
				}) : '';
				this.table.resize();
			}, () => {
				row.expandData.loading = false;
			});
		},
		
		sortHandle(col, row, type, e) {
			e.stopPropagation();
			
			col.$emit('sort', row, type, () => {
				row.moveTo(type);
			});
		},
		
		newChildHandle(col, row, e) {
			e.stopPropagation();
			var parent = row.expandData.parent;
			col.$emit('add', parent, data => {
				let row = this.createRow({
					data,
					expandLevel: parent.expandData.level + 1,
					expandParent: parent
				});
				parent.expandData.children.push(row);
				this.table.resize();
			});
		},
		
		insertHeader(config) {
			if (!this.header[config.level]) {
				this.$set(this.header, config.level, []);
			}
			
			var arr = this.header[config.level];
			if (config.fixed == 'left') {
				let index = arr.findIndex(item => {
					return item.fixed != 'left';
				});
				if (index == -1) {
					arr.push(config);
				} else {
					arr.splice(index, 0, config);
				}
			} else if (config.fixed == 'right') {
				arr.push(config);
			} else {
				let index = arr.findIndex(item => {
					return item.fixed == 'right';
				});
				index = index == -1 ? arr.length : index;
				arr.splice(index, 0, config);
			}
		},
		
		insertColumn(config) {
			this.columns.push(config);
		}
	}
});