<template>
	<div class="c-empty flex-column flex-center" :style="{'height': height}">
		<div class="c-empty-icon">
			<img :src="config.icon" />
		</div>
		<div class="c-empty-message">{{config.message}}</div>
		<ul class="c-empty-info">
			<li class="flex-between">
				<span>应用归属单位</span>
				<p>平阳县交通运输局</p>
			</li>
			<li class="flex-between">
				<span>应用管理员</span>
				<p>姜开明</p>
			</li>
			<li class="flex-between">
				<span>联系方式</span>
				<p>0577-6311 0290</p>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		props: {
			type: String,
			height: String,
			message: String
		},

		computed: {
			config() {
				switch(this.type) {
					case 'network':
						return {
							icon: require('@/assets/image/empty/network.png'),
							message: this.message || '当前服务不可用，请稍后重试'
						}
					
					default:
						return {
							icon: require('@/assets/image/empty/common.png'),
							message: this.message || '未查询到相关数据，请更换关键词'
						}
				}
			}
		}
	};
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/mobile.styl'
	
	.c-empty {
		height: 100%;
		
		mobile({
			height: 75vh
		})
		
		&-icon {
			width: 200px;
			height: 200px;
			
			mobile({
				width: 2.7rem,
				height: 2.7rem
			})
		}

		&-message {
			line-height: 52px;
			font-size: 16px;
			color: #818181;
			
			mobile({
				margin-top: .3rem,
				line-height: .72rem,
				font-size: .28rem
			})
		}
		
		&-info {
			width: 400px;
			margin-top: 20px;
			line-height: 40px;
			font-size: 14px;
			color: #818181;
			
			mobile({
				width: 6.5rem,
				margin-top: 1.6rem,
				line-height: .72rem,
				font-size: .28rem
			})
			
			p {
				color: #333;
			}
		}
	}
</style>
