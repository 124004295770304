<template>
    <div class="image flex-center" :style="{'width': compWidth, 'height': compHeight}">
		<video v-if="video" :src="src" loop autoplay></video>
		<img v-else :src="src">
		<a v-if="preview" class="flex-center" @click="$preview({src})"><i class="tf tf-search"></i></a>
	</div>
</template>

<script>
	export default {
		props: {
			src: String,
			
			width: [String, Number],
			
			height: [String, Number],
			
			size: {
				type: [String, Number],
				default: 80
			},
			
			mode: {
				type: String,
				default: 'aspectFit'
			},
			
			preview: {
				type: Boolean,
				default: true
			}
		},
		
		computed: {
			compWidth() {
				if (this.mode == 'heightFix') {
					return 'auto';
				}
				
				return this.parsePixel(this.size || this.width);
			},
			
			compHeight() {
				if (this.mode == 'widthFix') {
					return 'auto';
				}
				
				return this.parsePixel(this.size || this.height);
			},
			
			video() {
				if (!this.src) return false;
				var arr = this.src.split('.');
				
				return ['mp4', 'webm'].includes(arr[arr.length - 1]);
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.image {
		position: relative;
		width: 100%;
		height: 100%;
		background: #F5F5F5;
		
		video {
			width: 100%;
			height: 100%;
			background: #000;
		}
		
		&:hover a {
			display: flex;
		}
		
		a {
			display: none;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			font-size: 16px;
			color: #fff;
			background: rgba(0,0,0,.6);
		}
	}
</style>