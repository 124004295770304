<template>
	<div 
		class="c-switch" 
		:class="status ? 'z-open' : ''" 
		@click="switchHandle(!status)"
	>
		<div class="text" :style="{'width': textWidth, 'text-align': status ? 'left' : 'right'}">{{switchText[status ? 0 : 1]}}</div>
		<div class="handle" :style="{left: handleLeft}"></div>
	</div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number, Boolean],
            default: false
        },
        text: {
            type: String,
            default: '开启,关闭'
        }
    },
    
    data() {
        return {
            status: !!this.value
        }
    },
    
    computed: {
        switchText() {
            return this.text.split(',');
        },
        
        textWidth() {
            var length = this.switchText[0].length > this.switchText[1].length ? this.switchText[0].length : this.switchText[1].length;
            return (length * 13) + 20 + 'px';
        },
        
        handleLeft() {
            return this.status ? parseInt(this.textWidth) - 7 + 'px' : '3px';
        }
    },

    watch: {
        value(val) {
            this.status = !!val;
        }
    },
    
	methods: {
		switchHandle(status) {
            let stop = false;
            this.$emit('change', status, () => {
                stop = true;
            }, () => {
                this.status = status;
                this.$emit('input', status);
            });
            !stop && (this.status = status) && this.$emit('input', status);
		}
	}
}
</script>

<style lang="stylus" scoped>
	.c-switch {
        display: inline-block;
		position: relative;
        height: 26px;
        border-radius: 13px;
        padding: 0 8px;
		background: #D2D2D2;
		transition: all .2s ease;
		cursor: pointer;

		&.z-open {
			background: #4DD261;
			
			.text{
				color: #fff;
			}
		}
			
		.text{
            line-height: 26px;
            font-size: 13px;
            white-space: nowrap;
			color: #888;
		}

		.handle {
			position: absolute;
			background: #fff;
			border-radius: 50%;
			top: 3px;
            width: 20px;
            height: 20px;
            border-radius: 100%;
			transition: all .2s ease;
		}
	}
</style>
