import Store from './api.js'

window.cTableIndex = 0;

export function createStore(table) {
	const store = new Store();
	store.table = table;
	return store;
};

export function mapState(data) {
	const res = {};
	data.forEach(key => {
		res[key] = function() {
			return this.store[key];
		}
	});
	return res;
};