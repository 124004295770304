import { render, staticRenderFns } from "./color.vue?vue&type=template&id=faa91a00&scoped=true"
import script from "./color.vue?vue&type=script&lang=js"
export * from "./color.vue?vue&type=script&lang=js"
import style0 from "./color.vue?vue&type=style&index=0&id=faa91a00&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "faa91a00",
  null
  
)

export default component.exports