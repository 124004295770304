<template>
    <div class="g-module flex-column" :class="{'flex-grow-auto': !height}">
		<div v-if="title || tabList" class="g-module-head flex-between-center">
			<div v-if="tabList" class="tab flex">
				<div v-for="(item, i) in tabList" :key="i" class="tab-item" :class="{'z-crt': currentTab == item}" @click="tabHandle(item)">
					{{item.title}}
				</div>
			</div>
			<div v-else class="title flex-baseline">
				<p>{{title}}</p>
				<span v-if="subTitle">{{subTitle}}</span>
			</div>
		</div>
		<div class="g-module-content" :class="{'flex-column': flexColumn, 'flex': flexRow, 'flex-grow-auto': !columnScroll, 'flex-grow': columnScroll}" :style="{'height': parsePixel(height)}">
			<slot></slot>
		</div>
		<div v-if="$slots.button" class="g-module-button flex-center">
			<slot name="button"></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'module',
		
		props: {
			height: [Number, String],
			
			enableFlex: Boolean,
			
			title: String,
			
			subTitle: String
		},
		
		data() {
			return {
				complexWarpper: false,
				columnScroll: false,
				tabList: '',
				currentTab: ''
			}
		},
		
		computed: {
			compEnableFlex() {
				return this.enableFlex || this.complexWarpper;
			},
			
			flexColumn() {
				return this.compEnableFlex && !this.complexWarpper;
			},
			
			flexRow() {
				return this.compEnableFlex && this.complexWarpper;
			}
		},
		
		watch: {
			currentTab(val) {
				val.activated()
			}
		},
		
		methods: {
			resize() {
				this.$children.forEach(item => {
					if (['moduleTab', 'moduleArea', 'charts'].includes(item.$options.name)) {
						item.resize && item.activatedState !== false && item.resize();
					}
				});
				this.$emit('resize');
			},
			
			insertTab(tab) {
				if (!this.currentTab) {
					this.currentTab = tab;
				}
				if (!this.tabList) {
					this.tabList = [];
				}
				this.tabList.push(tab);
			},
			
			tabHandle(tab) {
				let stop = false;
				if (this.currentTab) {
					this.currentTab.deactivated(false, () => {
						stop = true;
					});
				}
				
				if (!stop) {
					this.currentTab.deactivated(true);
					this.currentTab = tab;
				}
			}
		}
	};
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/mobile'
	
	.g-module {
		position: relative;
		background: #fff;
		
		mobile({
			min-height: 100vh
		})
			
		&:nth-child(n+2) {
			margin-top: 10px;
		}
		
		&-head {
			z-index: 90;
			position: sticky;
			top: 0;
			min-height: 46px;
			border-bottom: 1px solid #ddd;
			background: #fff;
			
			mobile({
				display: none
			})
			
			.title {
				padding-left: 22px;
				
				p {
					font-size: 16px;
					font-weight: bold;
				}
				
				span {
					margin-left: 10px;
					font-size: 14px;
				}
			}
			
			.tab {
				&-item {
					position: relative;
					padding: 0 25px;
					line-height: 46px;
					font-size: 16px;
					color: #666;
					cursor: pointer;
					
					&:hover,
					&.z-crt {
						font-weight: bold;
						color: #000;
					}
					
					&:after {
						content: '';
						position: absolute;
						left: 50%;
						bottom: 0;
						width: 0;
						height: 2px;
						background: #C70000;
						transform: translate(-50%, 0);
						transition: width .3s ease;
					}
					
					&.z-crt:after {
						width: 30px;
					}
				}
			}
		}
		
		&-content {
			position: relative;
		}
			
		>>> &-tab {
			background: #fff;
			
			&:not(.z-active) {
				position: absolute;
				width: 100%;
				min-height: 100%;
			}
			
			&.z-active {
				z-index: 1;
				position: static;
			}
		}
		
		>>> &-column {
			&:nth-child(n+2) {
				border-left: 1px solid #ddd;
				margin-left: 2px;
			}
			
			&:nth-last-child(n+2) {
				border-right: 1px solid #ddd;
				margin-right: 2px;
			}
		}
		
		>>> &-button {
			z-index: 99;
			position: sticky;
			bottom: 0;
			min-height: 60px;
			border-top: 1px solid #E0E2EA;
			background: #fff;
			
			.c-button {
				margin: 0 8px;
			}
		}
	}
</style>
