<template>
	<div>
		<empty v-if="networkError" type="network" height="100vh"></empty>
		<router-view v-else-if="userInfo.id || loginState"></router-view>
		<input ref="upload" class="upload" type="file" :multiple="!!uploadConfig.multiple" :accept="uploadConfig.fileType" @change="uploadHandle">
		<div style="display: none;">
			<button ref="clipboard" id="clipboard" data-clipboard-action="copy" :data-clipboard-text="clipboardText"></button>
		</div>
	</div>
</template>

<script>
	import empty from '@/components/public/empty.vue'
	import clipboard from '@/assets/script/lib/clipboard.js'
	import {mapState} from 'vuex'
	import fun from '@/assets/script/function.js'
	
	export default {
		name: 'root',
		
		components: {
			empty
		},
		
		data() {
			return {
				uploadConfig: {},
				clipboardText: '',
				loginState: false
			}
		},
		
		computed: {
			...mapState(['userInfo', 'mobile', 'networkError'])
		},
		
		watch: {
			$route(val) {
				this.loginState = ['/login', '/register'].includes(val.path);
			},
			
			userInfo(val) {
				if (val.id && this.loginState) {
					this.navigateTo('/');
				}
			}
		},
		
		created() {
			window.document.documentElement.setAttribute('data-mobile', this.mobile);
		},
		
		mounted() {
			this.$nextTick(() => {
				this.dingLogin();
			});
			
			new clipboard('#clipboard').on('success', e => {
				this._clipboardMessage !== false && this.$message({
					text: this._clipboardMessage || '复制成功'
				});
                this._clipboardMessage = '';
			}).on('error', e =>{
				this.$message({
					text: '该浏览器不支持复制',
					icon: 'fail'
				});
			});
		},
		
		methods: {
			uploadFile(opt) {
				this.uploadConfig = opt;
				this.$nextTick(() => {
					this.$refs.upload.click();
				});
			},
			
			uploadHandle(e) {
				this.$loading();
				Promise.all(
					Array.from(e.target.files, file => {
						return new Promise((resolve, reject) => {
							var formdata = new FormData();
							formdata.append('file', file);
							formdata.append('enctype', 'multipart/form-data');
							
							if (this.uploadConfig.fileType && !this.uploadConfig.fileType.split(',').includes(file.name.substring(file.name.lastIndexOf('.')))) {
								reject('type');
								return;
							}
							
							if (this.uploadConfig.fileSize && file.size > parseInt(this.uploadConfig.fileSize) * 1024) {
								reject('size');
								return;
							}

							this.request({
								base: 'upload',
								url: '/file/upload',
								data: formdata,
								success: data => {
									resolve(data.file_path);
								},
								error: () => {
									this.$loadOver();
								}
							});
						});
					})
				).then(data => {
					this.$loadOver();
					this.uploadConfig.success(data);
				}, e => {
					this.$loadOver();
					
					if (e == 'type') {
						this.$message({
							text: `请选择类型为${this.uploadConfig.fileType}的文件`
						});
					} else if (e == 'size') {
						var size;
						if (this.uploadConfig.fileSize > 1024) {
							size = parseFloat((this.uploadConfig.fileSize / 1024).toFixed(2)) + 'MB';
						} else {
							size = this.uploadConfig.fileSize + 'KB';
						}
						
						this.$message({
							text: `文件大小不能大于${size}`
						});
					}
				});
				this.$refs.upload.value = '';
			},
			
			clipboard(text, message) {
				this.clipboardText = text;
                this._clipboardMessage = message;
				this.$nextTick(() => {
					this.$refs.clipboard.click();
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.upload {
		position: absolute;
		top: -100px;
	}
</style>

<style lang="stylus">
	@import './assets/style/reset';
	@import './assets/style/fonts';
	@import './assets/style/mobile'

	/* animation */
	.an {
		animation-fill-mode: forwards !important;

		&-fadein {
			opacity: 0;
			animation: fadeIn 0.4s;
		}

		&-fadeout {
			opacity: 1;
			animation: fadeOut 0.2s;
		}

		&-bounce {
			transform: scale(0.5);
			animation: bounce 0.4s;
		}
	}

	@keyframes bounce {
		0% {
			transform: scale(0.5);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes fadeOut {
		0% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}
	/* end animation */

	/* public */
	html {
		@media screen and (max-width: 750px) {
			font-size: 13.3333vw
		}
	}
	
	body {
		min-width: 1000px;
		min-height: 100vh;
		background: #F5F4F7;
		
		mobile({
			min-width: auto
		})
	}
	
	.s-link {
		color: #003DD9;
	}

	.cover-layer {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.4);
		z-index: 101;

		&.invisible {
			opacity: 0;
		}
	}
	
	.ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.ellipsis-2,
	.ellipsis-3,
	.ellipsis-n {
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: initial;
		-webkit-box-orient: vertical;
	}
	.ellipsis-2 {
		-webkit-line-clamp: 2;
	}
	.ellipsis-3 {
		-webkit-line-clamp: 3;
	}
	/* end public */

	/* flex */
	.flex {
		display: flex;
		flex-direction: row;

		&-start {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;

			&-main {
				display: flex;
				justify-content: flex-start;
				align-items: stretch;
			}
			&-cross {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
			}
			&-center {
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
			&-end {
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;
			}
			&-baseline {
				display: flex;
				justify-content: flex-start;
				align-items: baseline;
			}
			&-stretch {
				display: flex;
				justify-content: flex-start;
				align-items: stretch;
			}
		}

		&-center {
			display: flex;
			justify-content: center;
			align-items: center;

			&-main {
				display: flex;
				justify-content: center;
				align-items: stretch;
			}
			&-cross {
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
			&-start {
				display: flex;
				justify-content: center;
				align-items: flex-start;
			}
			&-end {
				display: flex;
				justify-content: center;
				align-items: flex-end;
			}
			&-baseline {
				display: flex;
				justify-content: center;
				align-items: baseline;
			}
			&-stretch {
				display: flex;
				justify-content: center;
				align-items: stretch;
			}
		}

		&-end {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;

			&-main {
				display: flex;
				justify-content: flex-end;
				align-items: stretch;
			}
			&-cross {
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;
			}
			&-start {
				display: flex;
				justify-content: flex-end;
				align-items: flex-start;
			}
			&-center {
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}
			&-baseline {
				display: flex;
				justify-content: flex-end;
				align-items: baseline;
			}
			&-stretch {
				display: flex;
				justify-content: flex-end;
				align-items: stretch;
			}
		}

		&-between {
			display: flex;
			justify-content: space-between;

			&-start {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
			}
			&-center {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			&-end {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
			}
			&-baseline {
				display: flex;
				justify-content: space-between;
				align-items: baseline;
			}
			&-stretch {
				display: flex;
				justify-content: space-between;
				align-items: stretch;
			}
		}

		&-around {
			display: flex;
			justify-content: space-around;

			&-start {
				display: flex;
				justify-content: space-around;
				align-items: flex-start;
			}
			&-center {
				display: flex;
				justify-content: space-around;
				align-items: center;
			}
			&-end {
				display: flex;
				justify-content: space-around;
				align-items: flex-end;
			}
			&-baseline {
				display: flex;
				justify-content: space-around;
				align-items: baseline;
			}
			&-stretch {
				display: flex;
				justify-content: space-around;
				align-items: stretch;
			}
		}

		&-stretch {
			display: flex;
			align-items: stretch;
		}

		&-baseline {
			display: flex;
			align-items: baseline;
		}

		&-column {
			display: flex;
			flex-direction: column;

			&.flex-reverse {
				display: flex;
				flex-direction: column-reverse;
			}

			> .flex-grow {
				width: initial;
				height: 0;
				
				&-auto {
					width: initial;
					height: auto;
				}
			}
		}
		
		&-reverse {
			display: flex;
			flex-direction: row-reverse;
		}

		&-wrap {
			display: flex;
			flex-wrap: wrap;
		}
		
		&-grow {
			flex-grow: 1;
			width: 0;
			
			&-auto {
				flex-grow: 1;
				width: auto;
			}
		}
		
		&-shrink {
			flex-shrink: 1;
		}
	}
	/* end flex */
</style>
