<template>
	<div class="c-radio-group" :class="{'column': column}">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'cRadioGroup',
		
		props: {
			value: [String, Number],
			
			column: Boolean
		},
		
		data() {
			return {
				valueData: this.value
			}
		},

		watch: {
			value(val) {
				this.valueData = val;
			},
			
			valueData() {
				this.update();
			}
		},
		
		mounted() {
			this.update();
		},

		methods: {
			update() {
				this.$children.forEach(item => {
					if (item.$options.name == 'cRadio') {
						item.checkHandle(item.value == this.valueData);
					}
				});
			},
			
			checkHandle(value) {
				this.valueData = value;
				this.$emit('input', value);
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.c-radio-group {
		overflow: hidden;
		
		&.column {
			.c-radio {
				float: left;
				clear: both;
				margin-right: 0;
				
				&:nth-child(n+2) {
					margin-top: 10px;
				}
			}
		}
		
		.c-radio {
			margin-right: 10px;
			
			&:nth-last-child(1) {
				margin-right: 0;
			}
		}
	}
</style>
