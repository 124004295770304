<template>
	<div class="list">
		<slot v-for="(item, i) in dataList" :data="item"></slot>
		<empty v-if="emptyState"></empty>
		<div v-else ref="loading" class="loading flex-row flex-center">
			<p v-if="endState">已经到底了！</p>
			<p v-else class="flex-center"><i class="tf tf-loading tf-spin"></i>正在加载...</p>
		</div>
	</div>
</template>

<script>
	import empty from '@/components/public/empty.vue'
	
	export default {
		components: {
			empty
		},
		
		props: {
			pageParam: { // 页数参数名
				type: String,
				default: 'pageIndex'
			},
			
			sizeParam: { // 分页大小参数名
				type: String,
				default: 'pageSize'
			},
			
			pageSize: { // 分页大小
				type: Number,
				default: 20
			}
		},
		
		data() {
			return {
				endState: false,
				emptyState: false,
				dataList: []
			}
		},
		
		mounted() {
			this._scrollHandle = () => {
				if (!this._requestObj && this.$refs.loading.getBoundingClientRect().top <= window.innerHeight * 1.5) {
					this.getData();
				}
			}
			window.addEventListener('scroll', this._scrollHandle);
		},
		
		beforeDestroy() {
			window.removeEventListener('scroll', this._scrollHandle);
		},
		
		methods: {
			load(option) {
				this._option = option;
				this.dataList = [];
				this.reload();
			},

			reload() {
				this.endState = false;
				this.emptyState = false;
				this.pageIndex = 0;

				if (this._requestObj) {
					this._requestObj.abort();
					this._requestObj = null;
				}
				
				this.getData();
			},

			getData() {
				if (!this._option || this._requestObj || this.endState) return;
				
				this._requestObj = this.request({
					url: this._option.url,
					data: (() => {
						var param = Object.assign({}, this._option.data);
						param[this.sizeParam] = this.pageSize;
						param[this.pageParam] = ++this.pageIndex;
						return param;
					})(),
					success: data => {
						var list = this._option.dataFormatter ? this._option.dataFormatter(data, this.pageIndex) : data,
							total = this.totalJudge ? this.totalJudge(data, this.pageIndex) : (list ? list.length : 0),
							empty = this.emptyJudge ? this.emptyJudge(data, this.pageIndex) : total == 0;

						if (this.pageIndex == 1) {
							this.dataList = [];
						}

						if (!empty) {
							this.dataList = this.dataList.concat(list);
						} else {
							if (this.pageIndex == 1) {
								this.emptyState = true;
							}
						}
						
						this.$emit('afterLoad', list, this.pageIndex, data);

						if (total < this.pageSize) {
							this.endState = true;
							this.$emit('afterEnd');
						}
					},
					fail: () => {
						if (this.pageIndex == 1) {
							this.emptyState = true;
						}
						this.endState = true;
					},
					complete: () => {
						this._requestObj = null;
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.list {
		overflow: hidden;
	}
	
	.loading {
		height: 1rem;
		font-size: .26rem;
		color: #363A44;
		
		.tf {
			margin-right: .2rem;
		}
	}
</style>