<template>
	<div ref="dom" class="c-select flex-between-center" :class="{'z-develop': developState, 'z-dis': disabled}" @click="developHandle">
		<input ref="input" v-if="search" type="text" class="flex-grow" :value="inputText" :placeholder="placeholder" @input="inputHandle" @click.stop="developHandle" @focus="focusState = true" @blur="focusState = false">
		<span v-else :class="{'z-empty': !selectedText}">{{selectedText || placeholder}}</span>
		<i class="tf tf-angle-down"></i>

		<div v-if="mobile" v-show="developState" class="cover-layer an an-fadein" style="z-index: 101;" @click="developHandle"></div>
		<div v-show="developState" class="c-select-options" :class="{'z-up': upSeat && !mobile}" :style="{'max-height': `${listHeight}px`}" @click.stop>
			<select-list ref="list" v-model="selectedValue" :options="optionsList" :default-option="defaultOption" :multiple="multiple"></select-list>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import fun from '@/assets/script/function.js'
	
	function render(h, parent, list, level = 0, option) {
		var arr = [];
		list && list.map(item => {
			item.parent = parent;
			arr = arr.concat([
				<div class={`flex-center-cross c-select-options-item ${!this.multiple && item.value == this.value ? 'z-crt' : ''}`} title={item.name} on-click={$event => this.clickHandle(item)}>
					{
						level ? [
							<div class="c-select-options-item-level tf tf-level-line" style={`margin-left: ${(level - 1) * 20}px;`}></div>
						] : []
					}
					{
						option.multiple ? [
							<c-checkbox class="checkbox" checked={!!item._checked}></c-checkbox>
						] : []
					}
					{item.name}
				</div>
			]).concat(render.call(this, h, item, item.children, level+1, option));
		});
		
		return arr;
	}
	
	const selectList = {
		render(h) {
			return (
				<div>
					{
						this.multiple ? [
							<div class="flex-center-cross c-select-options-item" on-click={$event => this.clickHandle('all')}><c-checkbox class="checkbox" checked={this.allChecked}></c-checkbox>全选</div>
						] : this.defaultOption ? [
							<div class="flex-center-cross c-select-options-item" style="color: #aaa;" on-click={$event => this.clickHandle()}>请选择</div>
						] : []
					}
					{
						render.call(this, h, undefined, this.options, 0, {
							multiple: this.multiple
						})
					}
				</div>
			)
		},
		
		props: {
			value: [String, Number],
			
			options: Array,
			
			defaultOption: Boolean,
			
			multiple: Boolean
		},
		
		computed: {
			allChecked() {
				return !this.options.find(item => !item._checked);
			}
		},
		
		methods: {
			clickHandle(item) {
				var that = this;
				if (this.multiple) {
					if (item != 'all') {
						this.$set(item, '_checked', !item._checked);
						
						if (item.children) {
							!function linkage(list, state) {
								list.forEach(item => {
									that.$set(item, '_checked', state);
									item.children && linkage(item.children, state)
								})
							}(item.children, item._checked)
						}
						
						if (item.parent) {
							!function linkage(item) {
								that.$set(item, '_checked', !item.children.find(item => !item._checked));
								item.parent && linkage(item.parent);
							}(item.parent)
						}
					}
					
					let arr = [], state = item == 'all' ? !this.allChecked : undefined;
					!function poll(list) {
						list.forEach(item => {
							state !== undefined && that.$set(item, '_checked', state);
							
							if (item._checked) {
								arr.push(item.value);
							}
							
							if (item.children) {
								poll(item.children)
							}
						})
					}(this.options)
					this.$emit('input', arr.join(','));
				} else {
					this.$emit('input', item ? item.value : '');
				}
				this.$parent.clickHandle();
			}
		}
	};
	
	export default {
		components: {
			selectList
		},
		
		props: {
			value: {
				type: [String, Number],
				default: ''
			},
			
			placeholder: {
				type: String,
				default: '请选择'
			},

			options: {
				type: [Array, String],
				default: () => {
					return [];
				}
			},
			
			defaultOption: {
				type: Boolean,
				default: true
			},
			
			multiple: Boolean,
			
			search: Boolean,
            
            disabled: Boolean,
			
			listHeight: {
				type: [Number, String],
				default: 300
			}
		},

		data() {
			return {
				optionsList: [],
				developState: false,
				upSeat: false,
				selectedValue: '',
				selectedText: '',
				focusState: false,
				keywordText: ''
			}
		},
		
		computed: {
			...mapState(['mobile']),
			
			searchState() {
				return !!this.keywordText;
			},
			
			inputText() {
				return this.keywordText || this.selectedText;
			}
		},
		
		watch: {
			value(val) {
				if (!this.optionsList.length) return;
				this.updateSelected();
			},
			
			options(val, old) {
				if (this._updateSelected) {
					this._updateSelected = false;
				} else {
					this.updateOptions(old);
				}
			},
			
			keywordText(val) {
				this.updateOptions();
			}
		},
		
		mounted() {
			this.updateOptions();
		},

		methods: {
			updateSelected() {
				var that = this;
				if (this.optionsList.length) {
					this._updateSelected = true;
					this.$nextTick(() => {
						this._updateSelected = false;
					});
					var arr = this.value.toString().split(','), value = [], text = [];
					
					!function poll(list, parent) {
						list.forEach(item => {
							that.$set(item, '_checked', arr.includes(item.value.toString()));
							if (item._checked) {
								value.push(item.value);
								
								if (!parent._checked) {
									text.push(item.name);
								}
							}
							item.children && poll(item.children, item);
						});
					}(this.optionsList, {});
					
					this.selectedValue = this.multiple ? value.join(',') : (value[0] || '');
					this.selectedText = text.join(',');
					this.clickHandle();
				}
			},
			
			updateOptions(old) {
				if (typeof this.options == 'string') {
					new Promise(resolve => {
						switch (this.options) {
						// 业主单位
						case 'OWNER_UNIT':
                            this.request({
                                url: '/yzdw/list',
                                success: data => {
                                    resolve(Array.from(data, item => {
                                        return {
                                            name: item.name,
                                            value: item.id
                                        }
                                    }));
                                }
                            });
							break;
							
						// 监理单位
						case 'SUPERVISE_UNIT':
                            this.request({
                                url: '/user/jl_list',
                                success: data => {
                                    resolve(Array.from(data, item => {
                                        return {
                                            name: item.name,
                                            value: item.id
                                        }
                                    }));
                                }
                            });
							break;
							
						// 企业列表
						case 'COMPANY_LIST':
							this.request({
								url: '/user/qy_list',
								data: {
									pageIndex: 1,
									pageSize: 20,
									name: this.keywordText
								},
								success: data => {
                                    resolve(Array.from(data, item => {
                                        return {
                                            name: item.name,
                                            value: item.id
                                        }
                                    }));
								}
							});
							break;
							
						// 保险公司列表
						case 'INSURANCE_COMPANY':
							this.request({
								url: '/bxgs/list',
								data: {
									pageIndex: 1,
									pageSize: 20,
									name: this.keywordText
								},
								success: data => {
                                    resolve(Array.from(data, item => {
                                        return {
                                            name: item.name,
                                            value: item.id
                                        }
                                    }));
								}
							});
							break;
							
						// 协助理赔
						case 'ASSIST_CLAIM':
                            this.request({
                                url: '/user/xzlp_list',
								data: {
									pageIndex: 1,
									pageSize: 20,
									name: this.keywordText
								},
                                success: data => {
                                    resolve(Array.from(data, item => {
                                        return {
                                            name: item.name,
                                            value: item.id
                                        }
                                    }));
                                }
                            });
							break;
							
						// 所属部门
						case 'ORGANIZATION':
                            this.request({
                                url: '/yzdw/organization/list',
								data: {
									pageIndex: 1,
									pageSize: 999,
									name: this.keywordText
								},
                                success: data => {
                                    resolve(Array.from(data, item => {
                                        return {
                                            name: item.organization_name,
                                            value: item.organization_id
                                        }
                                    }));
                                }
                            });
							break;
						}
					}).then(data => {
						this.optionsList = data;
						!this.searchState && this.updateSelected();
					});
				} else {
					if (this.searchState) {
						this.$emit('search', this.keywordText, list => {
							this.optionsList = list;
						});
						return;
					};
					
					this.optionsList = this.options;
					
					if (!old || !old.length) {
						this.updateSelected();
					} else {
						this.selectedValue = '';
						this.clickHandle();
					}
				}
			},
			
			developHandle() {
				if (this.developState || this.disabled) return;
				this.developState = true;
				let listHeight = this.listHeight > this.optionsList.length * 36 ? this.optionsList.length * 36 : this.listHeight;
				this.upSeat = window.innerHeight - this.$refs.dom.getBoundingClientRect().bottom < listHeight;
				setTimeout(() => {
					window.addEventListener('click', this.foldHandle);
				});
			},
			
			foldHandle() {
				if (this.focusState) return;
				this.developState = false;
				this.keywordText = '';
				window.removeEventListener('click', this.foldHandle);
			},

			clickHandle() {
				this.$emit('input', this.selectedValue);
				!this.multiple && this.foldHandle();
			},
			
			inputHandle: fun.debounce(function() {
				this.keywordText = this.$refs.input.value;
			}, 500, false)
		}
	}
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/mobile';
	
	.c-select {
		position: relative;
		box-sizing: border-box;
		height: 38px;
		border: 1px solid #ddd;
		padding: 0 8px;
		background: #fff;
		cursor: pointer;
		transition: border .3s ease;
		
		mobile({
			height: .8rem,
			padding: 0 .2rem
		})
		
		&:not(.z-dis):hover,
		&.z-develop {
			border-color: #bbb;
			
			normal({
				z-index: 100
			})
		}
		
		&.z-dis {
			color: #888;
			background: #f5f5f5;
			cursor: not-allowed;
		}
		
		input {
			height: 100%;
		}

		span {
			overflow: hidden;
			font-size: 14px;
			white-space: nowrap;
			text-overflow: ellipsis;
				
			mobile({
				font-size: .28rem
			})

			&.z-empty {
				color: #ccc;
			}
		}
		
		&-options {
			position: absolute;
			left: -1px;
			right: -1px;
			top: 100%;
			border: 1px solid #bbb;
			overflow: auto;
			background: #fff;
			
			mobile({
				z-index: 102,
				position: fixed,
				left: 0,
				right: 0,
				top: auto !important,
				bottom: 0,
				height: 40vh,
				border: none
			})
			
			&.z-up {
				top: 0;
				transform: translate(0,-100%);
			}
			
			>>> .c-select-options-item {
				mobile({
					padding: 0 .2rem,
					line-height: .8rem,
					font-size: .28rem
				})
			}

			>>> &-item {
				padding: 0 8px;
				overflow: hidden;
				line-height: 36px;
				font-size: 13px;
				white-space: nowrap;
				text-overflow: ellipsis;
				cursor: pointer;

				&:hover {
					color: #1492FF;
				}
				
				&.z-crt {
					color: #fff !important;
					background: #1492FF;
				}
				
				&-level {
					width: 15px;
					height: 28px;
					margin-right: 5px;
					text-align: right;
				}
				
				.checkbox {
					margin-right: 5px;
				}
			}
		}
	}
</style>
