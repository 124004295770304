<template>
	<div class="g-module-column flex-grow" :class="{'flex-column': enableFlex}" :style="{'min-width': parsePixel(width), 'max-width': parsePixel(width), 'overflow': scroll ? 'auto' : 'initial'}">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'moduleArea',
		
		props: {
			width: {
				type: [Number, String],
				default: 'auto'
			},
			
			enableFlex: Boolean,
			
			scroll: Boolean
		},
		
		created() {
			this.$parent.complexWarpper = true;
			if (this.scroll) {
				this.$parent.columnScroll = true;
			}
		},
		
		methods: {
			resize() {
				this.$children.forEach(item => {
					if (['charts'].includes(item.$options.name)) {
						item.resize && item.resize();
					}
				});
				this.$emit('resize');
			}
		}
	};
</script>