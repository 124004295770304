<template>
	<div>
		<div class="cover-layer invisible" :style="{'position': dom ? 'absolute' : 'fixed'}"></div>
		<div class="c-loading flex-center an an-fadein" :class="{'an-fadeout': end, 'z-text': !!text}" :style="{'position': dom ? 'absolute' : 'fixed'}">
			<i class="tf tf-loading tf-spin"></i>
			<p v-if="text">{{text}}</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'loading',
		
		data() {
			return {
				text: '',
                dom: '',
				end: false
			}
		},

		created() {
			this.$loadOver();
			window.$loadingObj = this;
			setTimeout(() => {
				this.close();
			}, 10000);
		},

		methods: {
			close() {
				this.end = true;
				setTimeout(() => {
					this.$destroy();
					this.$el.remove();
				}, 250);
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.c-loading {
		z-index: 999;
		left: 50%;
		top: 50%;
		width: 50px;
		height: 50px;
		border-radius: 4px;
		font-size: 30px;
		color: #fff;
		background: rgba(0,0,0,.7);
		transform: translate(-50%, -50%);
		
		&.z-text {
			width: auto;
			padding: 0 20px;
			
			p {
				margin-left: 12px;
				line-height: 18px;
				font-size: 14px;
			}
		}
	}
</style>
