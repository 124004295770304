<template>
    <div class="app" :style="{'background': background}">
		<slot></slot>
		
		<div v-if="$slots.foot" class="foot-place">
			<div class="foot flex-center-cross">
				<slot name="foot"></slot>
			</div>
		</div>
		
		<div v-if="createButton" class="create flex-center" @click="$emit('create')"><i class="tf tf-plus"></i></div>
		
		<div v-if="tabbar" class="tabbar-box">
			<div class="tabbar flex">
				<div v-for="(item, i) in tabbarList" :key="i" class="flex-column flex-center flex-grow item" :class="{'z-crt': $route.path == item.path}" @click="navigateTo(item.path)">
					<i class="tf" :class="[item.icon, {'z-crt': $route.path == item.path}]"></i>
					<p>{{item.name}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters} from 'vuex'
	
	export default {
		props: {
			tabbar: Boolean,
			createButton: Boolean,
			background: String
		},
		
		data() {
			return {
				tabbarList: [{
					icon: 'tf-tab-home',
					name: '工作台',
					path: '/'
				}, {
					icon: 'tf-tab-transact',
					name: '业务处理',
					path: '/transact'
				}, {
					icon: 'tf-tab-mine',
					name: '我的',
					path: '/mine'
				}],
				footer: true
			}
		}
	};
</script>

<style lang="stylus" scoped>
	.app {
		min-height: 100vh;
		
		.foot {
			z-index: 99;
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			height: 1.04rem;
			border-top: 1px solid #D8DCE5;
			padding: 0 .23rem;
			background: #fff;
			
			&-place {
				height: 1.04rem;
			}
			
			> .button-box {
				width: 100%;
				margin-top: 0;
				
				>>> .c-button {
					padding: 0 .5rem;
				}
			}
		}
		
		.create {
			position: fixed;
			right: .22rem;
			bottom: 1.6rem;
			width: 1rem;
			height: 1rem;
			border-radius: 100%;
			font-size: .36rem;
			color: #fff;
			background: #3296FA;
			box-shadow: 0 0 20px 0 rgba(0,92,184,.4);
		}
		
		.tabbar {
			z-index: 99;
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			height: 1.12rem;
			border-top: 1px solid #ddd;
			background: #fff;
			
			&-box {
				height: 1.12rem;
			}
			
			.item {
				&.z-crt {
					.tf, p {
						color: #3296FA;
					}
				}
				
				.tf {
					font-size: .4rem;
					color: #1F1F1F;
				}
				
				p {
					margin-top: .1rem;
					font-size: .28rem;
					color: #6D6D6D;
				}
			}
		}
	}
</style>
