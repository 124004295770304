<template>
	<c-dialog ref="dialog" fadein :title="false" width="auto" :button="false">
		<video v-if="video" class="video" :src="src" loop autoplay></video>
		<img v-else ref="img" class="preview" :src="src">
	</c-dialog>
</template>

<script>
	export default {
		name: 'preview',
		
		data() {
			return {
				src: ''
			}
		},
		
		computed: {
			video() {
				var arr = this.src.split('.');
				
				return ['mp4', 'webm'].includes(arr[arr.length - 1]);
			}
		},
		
		mounted() {
			if (!this.src) return;
			
			if (this.video) {
				this.$refs.dialog.open();
			} else {
				this.$loading();
				this.$refs.img.onload = () => {
					this.$loadOver();
					this.$refs.dialog.open();
				};
				this.$refs.img.onerror = () => {
					this.$loadOver();
					this.$message({
						text: '图片加载失败'
					})
				};
			}
		}
	}
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/mobile'
	
	>>> .c-dialog {
		background: none;
		
		&-close {
			top: 10px;
			right: 10px;
			width: 40px;
			height: 40px;
			border-radius: 100%;
			color: #fff;
			background: rgba(0,0,0,.6);
			
			&:hover {
				color: #fff;
			}
		}
	}
	
	.preview {
        min-width: 200px;
		max-width: 60vw;
        min-height: 200px;
		max-height: 60vh;
		
		mobile({
			max-width: 90vw,
			max-height: 90vh
		})
	}
	
	.video {
		width: 80vw;
		height: 60vh;
		background: #000;
	}
</style>
