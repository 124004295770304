<template>
	<div class="c-form-column">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'cForm',

		data() {
			return {
				store: this.$parent.store
			}
		}
	}
</script>
