<template>
	<div class="m-search">
		<div class="m-search-hadnle flex-center-cross">
			<c-button class="button" @click="search">查询</c-button>
			<c-button class="button" color="gray" hollow @click="clear">重置</c-button>
		</div>
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'mSearch',
		
		mounted() {
			this.$form = this.$children.find(item => item.$options.name == 'cForm');
		},

		methods: {
			search() {
				var data = this.$form.serialize();
				this.$emit('search', data);
			},
			
			clear() {
				this.$form.clear();
			}
		}
	}
</script>

<style lang="stylus">
	.m-search {
		padding: 20px;
		
		&-hadnle {
			.button {
				width: 74px;
				margin-right: 8px;
			}
		}
		
		.c-form {
			max-width: none;
			padding: 20px 0 0;
			
			&-item {
				padding-right: 0;
				margin-top: 12px;
				margin-right: 20px;
				background: #F5F7F9;
				
				&-label {
					height: 36px;
					padding-left: 10px;
					font-size: 13px;
					color: #666;
				}
				
				.c-input {
					height: 36px !important;
					border: none !important;
					background: none !important;
				}
				
				.c-radio, .c-checkbox {
					margin-top: 5px;
				}
				
				.c-select {
					height: 36px;
					border: none;
					background: none;
					
					&-options-item {
						line-height: 36px;
					}
				}
				
				.c-datetime {
					.el-date-editor {
						border: none;
						background: none;
						
						.el-input__inner {
							height: 36px;
						}
						
						.el-input__icon {
							display: none;
							line-height: 36px;
						}
					}
			
					.el-range-editor {
						height: 36px;
							
						.el-range-input {
							vertical-align: top;
							height: 36px;
						}
						
						.el-range-separator {
							vertical-align: top;
							height: 36px;
							line-height: 36px;
						}
						
						.el-input__icon {
							line-height: 36px;
						}
					}
				}
			}
		}
	}
</style>
