<template>
    <div class="c-table flex-column flex-grow-auto" :class="[{'z-auto': height == 'auto'}, `s-${skin}`]" :id="id" :style="{'height': parsePixel(height)}">
		<div class="c-table-body flex-column" :class="{'flex-grow-auto': height != 'auto'}">
			<div class="c-table-main flex-column flex-grow-auto">
				<c-thead :store="store"></c-thead>
				<c-tbody :store="store" :auto-height="height == 'auto'"></c-tbody>
			</div>
			
			<div v-show="columnsWidth.left > 1 && scrollXSize" class="c-table-fixed-left flex-column" :style="{'bottom': `${scrollXSize}px`, 'width': `${columnsWidth.left}px`}">
				<c-thead :store="store" fixed="left"></c-thead>
				<c-tbody :store="store" fixed="left"></c-tbody>
			</div>
			
			<div v-show="columnsWidth.right > 1 && scrollXSize" class="c-table-fixed-right flex-column" :style="{'bottom': `${scrollXSize}px`, 'right': `${scrollYSize}px`, 'width': `${columnsWidth.right}px`}">
				<c-thead :store="store" fixed="right" :left="fixedRightShifting"></c-thead>
				<c-tbody :store="store" fixed="right" :left="fixedRightShifting"></c-tbody>
			</div>
		
			<div v-show="scrollYSize" class="c-table-head-scroll-place" :style="{'width': `${scrollYSize}px`, 'height': `${47 * header.length + 1}px`}"></div>
		</div>
		
		<pagination v-if="!value && paging" ref="pagination" :total="total" @handle="loadData"></pagination>
		
		<div style="display:none;"><slot></slot></div>
    </div>
</template>

<script>
	import cThead from './thead.js'
	import cTbody from './tbody.js'
	import pagination from '@/components/public/pagination'
	import {createStore, mapState} from './store/index.js'
	
	export default {
		name: 'cTable',
		
		components: {
			cThead, cTbody, pagination
		},
		
		props: {
			skin: {
				type: String,
				default: 'list'
			},
			
			value: Array,  // 表格数据
			
			code: String,  // 表格code，用于获取字段
			
			height: [String, Number],  // 表格高度
			
			paging: {  // 是否分页
				type: Boolean,
				default: true
			}
		},
		
		data() {
			return {
				id: `cTableIndex${cTableIndex++}`,
				store: createStore(this),
				total: 0
			}
		},
		
		computed: {
			...mapState(['dataList', 'header', 'columnsWidth', 'scrollXSize', 'scrollYSize', 'emptyState']),
            
            fixedRightShifting() {
                return this.columnsWidth.right - this.columnsWidth.all + 'px';
            },
			
			pageCount() {
				return Math.ceil(this.total / this.pageSize) || 1;
			}
		},
		
		watch: {
			dataList(val) {
				this._updateData = true;
                setTimeout(() => {
                    this._updateData = false;
                });
				this.$emit('input', Array.from(val, item => item.data));
				this.resize();
			},
			
			value(val) {
				if (this._updateData) {
					this._updateData = false;
				} else{
					this.store.setData(val);
				}
			}
		},
		
		mounted() {
			this.bindEvent();
			this.resize();
			
			new Promise(resolve => {
				if (this.code) {
					this.request({
						url: '/system_table_field/get_table_field_list',
						data: {
							list_unique_code: this.code,
							enable_state: 1
						},
						success: data => {
							resolve(data);
						}
					});
				} else {
					resolve([]);
				}
			}).then(columnPower => {
				var list = [], selector, expand;
				this.$children.filter(item => item.$options.name == 'cTableColumn').forEach(col => {
					if (col.type == 'selector') {
						selector = col;
						return;
					} else if (col.type == 'expand') {
						expand = col;
						return;
					}
					
					let index = columnPower.findIndex(item => item.field_code == col.name),
						power = columnPower[index];
					
					if (power) {
						if (power.default_display == 1) {
							col.powerConfig = {
								fixed: power.is_locked == 2 ? 'left' : undefined
							}
							list.splice(index, 0, col);
						}
					} else {
						list.push(col);
					}
				});
				expand && list.unshift(expand);
				selector && list.unshift(selector);
				this.store.commit('columnConfig', list);
			});
			
			if (this.value) {
				this.store.setData(this.value);
			}
			
			this.store.commit('rowClick', !!this._events['row-click']);
		},
		
		activated() {
			this._hidden = false;
			this.resize();
		},
		
		deactivated() {
			this._hidden = true;
			this.store.commit('scrollX', false);
		},
		
		beforeDestroy() {
			window.removeEventListener('resize', this._resizeHandler);
		},
		
		methods: {
			bindEvent() {
				var $table = document.getElementById(this.id),
					$theadWrapper = document.querySelector('#' + this.id + ' .c-table-main .c-table-head-wrapper'),
					$tbodyWrapper = document.querySelector('#' + this.id + ' .c-table-main .c-table-body-wrapper'),
					$tbody = document.querySelector('#' + this.id + ' .c-table-main .c-table-body-wrapper table'),
					$fixedLeftTbodyWrapper = document.querySelector('#' + this.id + ' .c-table-fixed-left .c-table-body-wrapper'),
					$fixedRightTbodyWrapper = document.querySelector('#' + this.id + ' .c-table-fixed-right .c-table-body-wrapper');
					
				this._resizeHandler = () => {
					if (this._hidden) return;
					this.store.commit('scrollX', $table.clientWidth < this.columnsWidth.all + this.scrollYSize);
					this.store.commit('scrollY', $tbody.clientHeight > $tbodyWrapper.clientHeight);
				}
				
				window.addEventListener('resize', this._resizeHandler);
				
				$tbodyWrapper.onscroll = function() {
					$theadWrapper.scrollLeft = $tbodyWrapper.scrollLeft;
					$fixedLeftTbodyWrapper.scrollTop = $tbodyWrapper.scrollTop;
					$fixedRightTbodyWrapper.scrollTop = $tbodyWrapper.scrollTop;
				};
			},
			
			resize() {
				this.$nextTick(() => {
					this._resizeHandler();
				});
                setTimeout(() => {
                    this._resizeHandler();
                }, 100);
			},
			
			load(opt) {
				this.store.setData([]);
				this.opt = opt;
				this.update(1);
			},
			
			update(page) {
				this.$refs.pagination.update(page);
			},
			
			loadData(page) {
				if (!this.opt || !this.opt.url) return;
				
				this.requestObj && this.requestObj.abort();
                let load = this.$loading({
					zIndex: 99,
                    dom: document.querySelector('#' + this.id)
                });
				this.requestObj = this.request({
					url: this.opt.url,
					data: Object.assign(page, this.opt.data || {}),
					success: data => {
                        document.querySelector('#' + this.id + ' .c-table-main .c-table-body-wrapper').scrollTop = 0;
						data = data || [];
						if (this.opt.dataFormatter) {
							data = this.opt.dataFormatter(data);
						}
						this.store.setData(data);
						this.store.commit('emptyState', !data.length);
					},
					response: res => {
						this.total = res.total;
                        load.close();
					}
				});
			},
			
			getRow(filter = 'all') {
				if (filter == 'all') {
					return this.dataList;
				} else if (filter == 'selected') {
					return this.getRow(item => item.selected);
				} else if (typeof filter == 'function') {
					let list = [];
					!function poll(arr) {
						if (!arr) return;
						arr.forEach((item, index) => {
							if (filter(item)) {
								list.push(item);
							}
							
							poll(item.expandData.children);
						});
					}(this.dataList);
					return list;
				} else {
					let list = [];
					(typeof filter != 'object' ? [filter] : filter).forEach(item => {
						list.push(this.dataList[item]);
					});
					return typeof filter == 'object' ? list : list[0];
				}
			},
			
			push(data) {
				this.store.dataList.push(this.store.createRow({
					data
				}));
			},
            
            insert(index, data) {
                if (index !== undefined) {
                    this.store.dataList.splice(index, 0, this.store.createRow({
                        data
                    }));
                }
            }
		}
	};
</script>

<style lang="stylus">
	@import './style/index'
</style>
