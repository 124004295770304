<template>
	<div class="c-detail-item flex" :style="{'width': mobile || full ? '100%' : '50%'}">
		<label class="c-detail-item-label">{{label}}</label>
		<div class="c-detail-item-content flex-grow">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			label: String,
			
			full: Boolean
		},
		
		data() {
			return {
				mobile: this.$store.state.mobile
			}
		}
	};
</script>